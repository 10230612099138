import Text from 'components/Text';
import { TEXT_STRING, routes } from '../../../constants';
import ListItem from '../ListItem';
import Icon from 'components/Icon';
import { useNavigate } from 'react-router-dom';

const STR = TEXT_STRING.MY_PAGE;
const loveHotelsUrl = process.env.REACT_APP_LOVE_HOTELS_URL || '';

const companyInfo = [
  {
    icon: 'building',
    label: STR.LOVE_HOTELS,
    url: loveHotelsUrl,
    isRedirect: true,
  },
  {
    icon: 'light',
    label: STR.HOW_TO_USE,
    url: routes.HOW_TO_USE,
  },
  { icon: 'question', label: STR.FAQ, url: routes.FAQ },
];

const accountInfo = [
  // { icon: 'info', label: STR.ABOUT_APP },
  // {
  //   icon: 'file',
  //   label: STR.COMMUNICATION_GUIDE,
  // },
  {
    icon: 'fileHeart',
    label: STR.TERMS_OF_SERVICE,
    url: routes.TERMS_OF_SERVICE,
  },
  {
    icon: 'fileNormal',
    label: STR.SPECIAL_COMMERCIAL_LAW,
    url: routes.SPECIAL_COMMERCIAL_LAW,
  },
  {
    icon: 'shield',
    label: STR.PRIVACY_POLICY,
    url: routes.PRIVACY_POLICY,
  },
  // {
  //   icon: 'fileCheck',
  //   label: STR.LEGAL_COMPLIANCE_POLICY,
  // },
];

function AppInfo() {
  const navigate = useNavigate();

  return (
    <div className="py-20px flex flex-col gap-3 border-b border-neutral-50">
      <Text textColor="text-primary" bold fontSize={14}>
        {STR.ABOUT_APP}
      </Text>
      {companyInfo.map((item, index) => (
        <ListItem
          key={index}
          text={item.label}
          prefix={<Icon icon={item.icon} />}
          className="cursor-pointer"
          onClick={() =>
            item.isRedirect
              ? window.open(item.url, '_blank')
              : navigate(item.url)
          }
        />
      ))}
      <Text bold fontSize={14}>
        {TEXT_STRING.COMMON.OTHERS}
      </Text>
      {accountInfo.map((item, index) => {
        return (
          <ListItem
            key={index}
            text={item.label}
            prefix={<Icon icon={item.icon} />}
            className="cursor-pointer"
            onClick={() => item.url && navigate(item.url)}
          />
        );
      })}
    </div>
  );
}

export default AppInfo;
