const icons: Record<
  string,
  ({
    width,
    height,
    color,
  }: {
    width?: number;
    height?: number;
    color?: string;
  }) => JSX.Element
> = {
  arrowRight: ({ color = 'var(--color-neutral-600)' }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M3.33301 10H16.6663M16.6663 10L11.6663 5M16.6663 10L11.6663 15"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  bell: ({ color = 'black' }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M14.9997 19C14.9997 20.6569 13.6566 22 11.9997 22C10.3429 22 8.99972 20.6569 8.99972 19M13.7962 6.23856C14.2317 5.78864 14.4997 5.17562 14.4997 4.5C14.4997 3.11929 13.3804 2 11.9997 2C10.619 2 9.49972 3.11929 9.49972 4.5C9.49972 5.17562 9.76772 5.78864 10.2032 6.23856M17.9997 11.2C17.9997 9.82087 17.3676 8.49823 16.2424 7.52304C15.1171 6.54786 13.591 6 11.9997 6C10.4084 6 8.8823 6.54786 7.75708 7.52304C6.63186 8.49823 5.99972 9.82087 5.99972 11.2C5.99972 13.4818 5.43385 15.1506 4.72778 16.3447C3.92306 17.7056 3.5207 18.3861 3.53659 18.5486C3.55476 18.7346 3.58824 18.7933 3.73906 18.9036C3.87089 19 4.53323 19 5.85791 19H18.1415C19.4662 19 20.1286 19 20.2604 18.9036C20.4112 18.7933 20.4447 18.7346 20.4629 18.5486C20.4787 18.3861 20.0764 17.7056 19.2717 16.3447C18.5656 15.1506 17.9997 13.4818 17.9997 11.2Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  bellRing: ({ color = 'var(--color-neutral-550)' }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M11.6669 17.5001H8.33358M1.91178 4.84999C1.89983 3.64054 2.5519 2.51112 3.60529 1.91675M18.0854 4.85C18.0974 3.64054 17.4453 2.51112 16.3919 1.91675M15.0002 6.66675C15.0002 5.34067 14.4735 4.0689 13.5358 3.13121C12.5981 2.19353 11.3263 1.66675 10.0002 1.66675C8.67417 1.66675 7.4024 2.19353 6.46471 3.13121C5.52703 4.0689 5.00025 5.34067 5.00025 6.66675C5.00025 9.2419 4.35064 11.005 3.62497 12.1713C3.01285 13.155 2.7068 13.6468 2.71802 13.784C2.73045 13.936 2.76263 13.9939 2.88506 14.0847C2.99563 14.1667 3.49407 14.1667 4.49096 14.1667H15.5095C16.5064 14.1667 17.0049 14.1667 17.1154 14.0847C17.2379 13.9939 17.2701 13.936 17.2825 13.784C17.2937 13.6468 16.9876 13.155 16.3755 12.1713C15.6499 11.005 15.0002 9.2419 15.0002 6.66675Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  building: ({ color = 'var(--color-neutral-550)' }) => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.24984 9.16667H3.83317C3.36646 9.16667 3.1331 9.16667 2.95485 9.25749C2.79804 9.33739 2.67056 9.46487 2.59067 9.62167C2.49984 9.79993 2.49984 10.0333 2.49984 10.5V17.5M13.7498 9.16667H16.1665C16.6332 9.16667 16.8666 9.16667 17.0448 9.25749C17.2016 9.33739 17.3291 9.46487 17.409 9.62167C17.4998 9.79993 17.4998 10.0333 17.4998 10.5V17.5M13.7498 17.5V5.16667C13.7498 4.23325 13.7498 3.76654 13.5682 3.41002C13.4084 3.09641 13.1534 2.84144 12.8398 2.68166C12.4833 2.5 12.0166 2.5 11.0832 2.5H8.9165C7.98308 2.5 7.51637 2.5 7.15985 2.68166C6.84625 2.84144 6.59128 3.09641 6.43149 3.41002C6.24984 3.76654 6.24984 4.23325 6.24984 5.16667V17.5M18.3332 17.5H1.6665M9.1665 5.83333H10.8332M9.1665 9.16667H10.8332M9.1665 12.5H10.8332"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  calendar: ({ color = 'var(--color-neutral-400)' }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M19.25 9.16634H2.75M14.6667 1.83301V5.49967M7.33333 1.83301V5.49967M7.15 20.1663H14.85C16.3901 20.1663 17.1602 20.1663 17.7485 19.8666C18.2659 19.603 18.6866 19.1823 18.9503 18.6648C19.25 18.0766 19.25 17.3065 19.25 15.7663V8.06634C19.25 6.5262 19.25 5.75612 18.9503 5.16787C18.6866 4.65042 18.2659 4.22972 17.7485 3.96607C17.1602 3.66634 16.3901 3.66634 14.85 3.66634H7.15C5.60986 3.66634 4.83978 3.66634 4.25153 3.96607C3.73408 4.22972 3.31338 4.65042 3.04973 5.16787C2.75 5.75612 2.75 6.5262 2.75 8.06634V15.7663C2.75 17.3065 2.75 18.0766 3.04973 18.6648C3.31338 19.1823 3.73408 19.603 4.25153 19.8666C4.83978 20.1663 5.60986 20.1663 7.15 20.1663Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  calendarHeart: ({ color = 'var(--color-neutral)' }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 8H3M16 2V5M8 2V5M7.8 22H16.2C17.8802 22 18.7202 22 19.362 21.673C19.9265 21.3854 20.3854 20.9265 20.673 20.362C21 19.7202 21 18.8802 21 17.2V8.8C21 7.11984 21 6.27976 20.673 5.63803C20.3854 5.07354 19.9265 4.6146 19.362 4.32698C18.7202 4 17.8802 4 16.2 4H7.8C6.11984 4 5.27976 4 4.63803 4.32698C4.07354 4.6146 3.6146 5.07354 3.32698 5.63803C3 6.27976 3 7.11984 3 8.8V17.2C3 18.8802 3 19.7202 3.32698 20.362C3.6146 20.9265 4.07354 21.3854 4.63803 21.673C5.27976 22 6.11984 22 7.8 22ZM11.9973 12.3306C11.1975 11.4216 9.8639 11.1771 8.86188 12.0094C7.85986 12.8418 7.71879 14.2335 8.50568 15.2179C9.077 15.9327 10.6593 17.3397 11.4833 18.0569C11.662 18.2124 11.7513 18.2902 11.856 18.321C11.9466 18.3477 12.0479 18.3477 12.1386 18.321C12.2432 18.2902 12.3325 18.2124 12.5112 18.0569C13.3353 17.3397 14.9175 15.9327 15.4888 15.2179C16.2757 14.2335 16.1519 12.8331 15.1326 12.0094C14.1134 11.1858 12.797 11.4216 11.9973 12.3306Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  chatLove: ({ color = 'var(--color-neutral-550)' }) => (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 5.5C1.5 4.09987 1.5 3.3998 1.77248 2.86502C2.01217 2.39462 2.39462 2.01217 2.86502 1.77248C3.3998 1.5 4.09987 1.5 5.5 1.5H12.5C13.9001 1.5 14.6002 1.5 15.135 1.77248C15.6054 2.01217 15.9878 2.39462 16.2275 2.86502C16.5 3.3998 16.5 4.09987 16.5 5.5V10C16.5 11.4001 16.5 12.1002 16.2275 12.635C15.9878 13.1054 15.6054 13.4878 15.135 13.7275C14.6002 14 13.9001 14 12.5 14H10.4031C9.88308 14 9.62306 14 9.37435 14.051C9.15369 14.0963 8.94017 14.1712 8.73957 14.2737C8.51347 14.3892 8.31043 14.5517 7.90434 14.8765L5.91646 16.4668C5.56973 16.7442 5.39636 16.8829 5.25045 16.8831C5.12356 16.8832 5.00352 16.8255 4.92436 16.7263C4.83333 16.6123 4.83333 16.3903 4.83333 15.9463V14C4.05836 14 3.67087 14 3.35295 13.9148C2.49022 13.6836 1.81635 13.0098 1.58519 12.147C1.5 11.8291 1.5 11.4416 1.5 10.6667V5.5Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99743 5.41604C8.24767 4.55033 6.9974 4.31746 6.05801 5.11019C5.11862 5.90292 4.98636 7.22832 5.72407 8.16589C6.18111 8.74674 7.32824 9.80762 8.13496 10.5308C8.43129 10.7964 8.57946 10.9293 8.75727 10.9828C8.90981 11.0287 9.08505 11.0287 9.23759 10.9828C9.41541 10.9293 9.56357 10.7964 9.8599 10.5308C10.6666 9.80762 11.8138 8.74674 12.2708 8.16589C13.0085 7.22832 12.8924 5.89458 11.9369 5.11019C10.9813 4.3258 9.74719 4.55033 8.99743 5.41604Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  check: ({ color = 'var(--color-primary)' }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M10 3L4.5 8.5L2 6"
        stroke={color}
        strokeWidth="1.6666"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  roundedCheck: ({ color = 'var(--color-primary)' }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11561_24467)">
        <rect width="24" height="24" rx="12" fill={color} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.096 7.38967L9.93602 14.2997L8.03602 12.2697C7.68602 11.9397 7.13602 11.9197 6.73602 12.1997C6.34602 12.4897 6.23602 12.9997 6.47602 13.4097L8.72602 17.0697C8.94602 17.4097 9.32601 17.6197 9.75601 17.6197C10.166 17.6197 10.556 17.4097 10.776 17.0697C11.136 16.5997 18.006 8.40967 18.006 8.40967C18.906 7.48967 17.816 6.67967 17.096 7.37967V7.38967Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_11561_24467">
          <rect width="24" height="24" rx="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  chevronLeft: ({ color = 'var(--color-neutral-550)' }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M15 18.5L9 12.5L15 6.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  chevronRight: ({ color = 'var(--color-neutral-550)' }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M7.5 15L12.5 10L7.5 5"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  chevronDownDouble: ({ color = 'white' }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 13L12 18L17 13M7 6L12 11L17 6"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  clock: ({ color = 'var(--color-neutral-400)' }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_978_2382)">
        <path
          d="M10.0003 5.00033V10.0003L13.3337 11.667M18.3337 10.0003C18.3337 14.6027 14.6027 18.3337 10.0003 18.3337C5.39795 18.3337 1.66699 14.6027 1.66699 10.0003C1.66699 5.39795 5.39795 1.66699 10.0003 1.66699C14.6027 1.66699 18.3337 5.39795 18.3337 10.0003Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_978_2382">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  close: ({ color = 'var(--color-neutral)' }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M18 6L6 18M6 6L18 18"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  copy: ({ color = 'var(--color-neutral-400)' }) => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13388_97185)">
        <path
          d="M13.3337 13.3327V15.666C13.3337 16.5994 13.3337 17.0661 13.152 17.4227C12.9922 17.7363 12.7372 17.9912 12.4236 18.151C12.0671 18.3327 11.6004 18.3327 10.667 18.3327H4.33366C3.40024 18.3327 2.93353 18.3327 2.57701 18.151C2.2634 17.9912 2.00844 17.7363 1.84865 17.4227C1.66699 17.0661 1.66699 16.5994 1.66699 15.666V9.33268C1.66699 8.39926 1.66699 7.93255 1.84865 7.57603C2.00844 7.26243 2.2634 7.00746 2.57701 6.84767C2.93353 6.66602 3.40024 6.66602 4.33366 6.66602H6.66699M9.33366 13.3327H15.667C16.6004 13.3327 17.0671 13.3327 17.4236 13.151C17.7372 12.9912 17.9922 12.7363 18.152 12.4227C18.3337 12.0661 18.3337 11.5994 18.3337 10.666V4.33268C18.3337 3.39926 18.3337 2.93255 18.152 2.57603C17.9922 2.26243 17.7372 2.00746 17.4236 1.84767C17.0671 1.66602 16.6004 1.66602 15.667 1.66602H9.33366C8.40024 1.66602 7.93353 1.66602 7.57701 1.84767C7.2634 2.00746 7.00844 2.26243 6.84865 2.57603C6.66699 2.93255 6.66699 3.39926 6.66699 4.33268V10.666C6.66699 11.5994 6.66699 12.0661 6.84865 12.4227C7.00844 12.7363 7.2634 12.9912 7.57701 13.151C7.93353 13.3327 8.40024 13.3327 9.33366 13.3327Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_13388_97185">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  computer: ({ color = 'var(--color-neutral-400)' }) => (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 12L21 12M7 19H15M5.8 16H16.2C17.8802 16 18.7202 16 19.362 15.673C19.9265 15.3854 20.3854 14.9265 20.673 14.362C21 13.7202 21 12.8802 21 11.2V5.8C21 4.11984 21 3.27976 20.673 2.63803C20.3854 2.07354 19.9265 1.6146 19.362 1.32698C18.7202 1 17.8802 1 16.2 1H5.8C4.11984 1 3.27976 1 2.63803 1.32698C2.07354 1.6146 1.6146 2.07354 1.32698 2.63803C1 3.27976 1 4.11984 1 5.8V11.2C1 12.8802 1 13.7202 1.32698 14.362C1.6146 14.9265 2.07354 15.3854 2.63803 15.673C3.27976 16 4.11984 16 5.8 16Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  creditCard: ({ color = '#2E8EFF', width = 20, height = width }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M18.8333 8.33332H2.16667M2.16667 6.83332L2.16667 13.1667C2.16667 14.1001 2.16667 14.5668 2.34833 14.9233C2.50812 15.2369 2.76308 15.4919 3.07669 15.6517C3.43321 15.8333 3.89992 15.8333 4.83334 15.8333L16.1667 15.8333C17.1001 15.8333 17.5668 15.8333 17.9233 15.6517C18.2369 15.4919 18.4919 15.2369 18.6517 14.9233C18.8333 14.5668 18.8333 14.1001 18.8333 13.1667V6.83332C18.8333 5.8999 18.8333 5.43319 18.6517 5.07667C18.4919 4.76307 18.2369 4.5081 17.9233 4.34831C17.5668 4.16666 17.1001 4.16666 16.1667 4.16666L4.83334 4.16666C3.89992 4.16666 3.43321 4.16666 3.07669 4.34831C2.76308 4.5081 2.50812 4.76307 2.34833 5.07667C2.16667 5.43319 2.16667 5.8999 2.16667 6.83332Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  dotVertical: ({ color = 'var(--color-neutral)' }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  doubleHeart: ({ color = 'var(--color-neutral-550)' }) => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2960_29517)">
        <path
          d="M12.9545 8.27127C13.2661 7.71761 13.5259 7.21801 13.6861 6.83776C14.4643 4.9913 13.6948 2.86739 11.814 2.00102C9.93306 1.13465 8.04434 2.03299 7.21543 3.76752C5.63031 2.67983 3.51582 2.83957 2.34974 4.53694C1.18366 6.23432 1.54963 8.45177 3.14674 9.66178C3.87159 10.2109 5.30514 11.0202 6.6548 11.7405M13.5808 9.7917C13.2291 7.90174 11.621 6.51953 9.5962 6.89521C7.57144 7.27089 6.26223 9.09765 6.53596 11.0826C6.75588 12.6773 7.96977 16.419 8.43851 17.8284C8.50247 18.0207 8.53445 18.1169 8.59778 18.184C8.65294 18.2424 8.72628 18.2847 8.80445 18.3033C8.89419 18.3246 8.99346 18.3042 9.192 18.2634C10.6469 17.9647 14.4943 17.1451 15.9853 16.5382C17.8412 15.7828 18.799 13.7369 18.0816 11.7942C17.3642 9.85164 15.3935 9.15134 13.5808 9.7917Z"
          stroke={color}
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2960_29517">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  edit: ({ color = 'var(--color-neutral)' }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M2.39637 15.0963C2.43466 14.7517 2.4538 14.5794 2.50594 14.4184C2.55219 14.2755 2.61753 14.1396 2.70021 14.0142C2.79339 13.8729 2.91596 13.7503 3.16112 13.5052L14.1664 2.49992C15.0868 1.57945 16.5792 1.57945 17.4997 2.49993C18.4202 3.4204 18.4202 4.91279 17.4997 5.83326L6.49445 16.8385C6.2493 17.0836 6.12672 17.2062 5.98541 17.2994C5.86005 17.3821 5.72408 17.4474 5.58121 17.4937C5.42018 17.5458 5.24789 17.5649 4.90331 17.6032L2.08301 17.9166L2.39637 15.0963Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  eye: ({ color = 'var(--color-neutral)' }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
    >
      <path
        d="M1.61342 6.47543C1.52262 6.33167 1.47723 6.25979 1.45182 6.14892C1.43273 6.06565 1.43273 5.93431 1.45182 5.85104C1.47723 5.74017 1.52262 5.66829 1.61341 5.52453C2.36369 4.33654 4.59693 1.33331 8.00027 1.33331C11.4036 1.33331 13.6369 4.33654 14.3871 5.52453C14.4779 5.66829 14.5233 5.74017 14.5487 5.85104C14.5678 5.93431 14.5678 6.06565 14.5487 6.14892C14.5233 6.25979 14.4779 6.33167 14.3871 6.47543C13.6369 7.66342 11.4036 10.6666 8.00027 10.6666C4.59693 10.6666 2.36369 7.66342 1.61342 6.47543Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00027 7.99998C9.10484 7.99998 10.0003 7.10455 10.0003 5.99998C10.0003 4.89541 9.10484 3.99998 8.00027 3.99998C6.8957 3.99998 6.00027 4.89541 6.00027 5.99998C6.00027 7.10455 6.8957 7.99998 8.00027 7.99998Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  eyeOff: ({ color = 'black' }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
    >
      <path
        d="M9.74294 3.09232C10.1494 3.03223 10.5686 3 11.0004 3C16.1054 3 19.4553 7.50484 20.5807 9.28682C20.7169 9.5025 20.785 9.61034 20.8231 9.77667C20.8518 9.90159 20.8517 10.0987 20.8231 10.2236C20.7849 10.3899 20.7164 10.4985 20.5792 10.7156C20.2793 11.1901 19.8222 11.8571 19.2165 12.5805M5.72432 4.71504C3.56225 6.1817 2.09445 8.21938 1.42111 9.28528C1.28428 9.50187 1.21587 9.61016 1.17774 9.77648C1.1491 9.9014 1.14909 10.0984 1.17771 10.2234C1.21583 10.3897 1.28393 10.4975 1.42013 10.7132C2.54554 12.4952 5.89541 17 11.0004 17C13.0588 17 14.8319 16.2676 16.2888 15.2766M2.00042 1L20.0004 19M8.8791 7.87868C8.3362 8.42157 8.00042 9.17157 8.00042 10C8.00042 11.6569 9.34356 13 11.0004 13C11.8288 13 12.5788 12.6642 13.1217 12.1213"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  file: ({ color = 'var(--color-neutral-550)' }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M11.6666 9.16675H6.66659M8.33325 12.5001H6.66659M13.3333 5.83341H6.66659M16.6666 5.66675V14.3334C16.6666 15.7335 16.6666 16.4336 16.3941 16.9684C16.1544 17.4388 15.772 17.8212 15.3016 18.0609C14.7668 18.3334 14.0667 18.3334 12.6666 18.3334H7.33325C5.93312 18.3334 5.23306 18.3334 4.69828 18.0609C4.22787 17.8212 3.84542 17.4388 3.60574 16.9684C3.33325 16.4336 3.33325 15.7335 3.33325 14.3334V5.66675C3.33325 4.26662 3.33325 3.56655 3.60574 3.03177C3.84542 2.56137 4.22787 2.17892 4.69828 1.93923C5.23306 1.66675 5.93312 1.66675 7.33325 1.66675H12.6666C14.0667 1.66675 14.7668 1.66675 15.3016 1.93923C15.772 2.17892 16.1544 2.56137 16.3941 3.03177C16.6666 3.56655 16.6666 4.26662 16.6666 5.66675Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  fileClock: ({ color = 'var(--color-neutral-550)' }) => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4167 1.66675H12.6667C14.0668 1.66675 14.7669 1.66675 15.3017 1.93923C15.7721 2.17892 16.1545 2.56137 16.3942 3.03177C16.6667 3.56655 16.6667 4.26662 16.6667 5.66675V14.3334C16.6667 15.7335 16.6667 16.4336 16.3942 16.9684C16.1545 17.4388 15.7721 17.8212 15.3017 18.0609C14.7669 18.3334 14.0668 18.3334 12.6667 18.3334H7.33337C5.93324 18.3334 5.23318 18.3334 4.6984 18.0609C4.22799 17.8212 3.84554 17.4388 3.60586 16.9684C3.33337 16.4336 3.33337 15.7335 3.33337 14.3334V13.7501M13.3334 10.8334H9.58337M13.3334 7.50008H10.4167M13.3334 14.1667H6.66671"
        stroke={color}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.58337 5V6.25L5.83337 7.08333M8.33337 6.25C8.33337 7.24456 7.93829 8.19839 7.23502 8.90165C6.53176 9.60491 5.57794 10 4.58337 10C3.58881 10 2.63498 9.60491 1.93172 8.90165C1.22846 8.19839 0.833374 7.24456 0.833374 6.25C0.833374 5.25544 1.22846 4.30161 1.93172 3.59835C2.63498 2.89509 3.58881 2.5 4.58337 2.5C5.57794 2.5 6.53176 2.89509 7.23502 3.59835C7.93829 4.30161 8.33337 5.25544 8.33337 6.25Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  fileCheck: ({ color = 'var(--color-neutral-550)' }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M16.6666 10.4167V5.66675C16.6666 4.26662 16.6666 3.56655 16.3941 3.03177C16.1544 2.56137 15.772 2.17892 15.3016 1.93923C14.7668 1.66675 14.0667 1.66675 12.6666 1.66675H7.33325C5.93312 1.66675 5.23305 1.66675 4.69828 1.93923C4.22787 2.17892 3.84542 2.56137 3.60574 3.03177C3.33325 3.56655 3.33325 4.26662 3.33325 5.66675V14.3334C3.33325 15.7335 3.33325 16.4336 3.60574 16.9684C3.84542 17.4388 4.22787 17.8212 4.69828 18.0609C5.23305 18.3334 5.93312 18.3334 7.33325 18.3334H9.99992M11.6666 9.16675H6.66659M8.33325 12.5001H6.66659M13.3333 5.83341H6.66659M12.0833 15.8334L13.7499 17.5001L17.4999 13.7501"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  fileHeart: ({
    color = 'var(--color-neutral-550)',
    width = 20,
    height = width,
  }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M16.6666 8.33341V5.66675C16.6666 4.26662 16.6666 3.56655 16.3941 3.03177C16.1544 2.56137 15.772 2.17892 15.3016 1.93923C14.7668 1.66675 14.0667 1.66675 12.6666 1.66675H7.33325C5.93312 1.66675 5.23305 1.66675 4.69828 1.93923C4.22787 2.17892 3.84542 2.56137 3.60574 3.03177C3.33325 3.56655 3.33325 4.26662 3.33325 5.66675V14.3334C3.33325 15.7335 3.33325 16.4336 3.60574 16.9684C3.84542 17.4388 4.22787 17.8212 4.69828 18.0609C5.23305 18.3334 5.93312 18.3334 7.33325 18.3334H9.99992M10.4166 9.16675H6.66659M7.49992 12.5001H6.66659M13.3333 5.83341H6.66659M14.1643 12.3589C13.4979 11.6014 12.3865 11.3977 11.5515 12.0913C10.7165 12.7849 10.5989 13.9447 11.2547 14.765C11.9104 15.5854 14.1643 17.5001 14.1643 17.5001C14.1643 17.5001 16.4182 15.5854 17.074 14.765C17.7297 13.9447 17.6265 12.7776 16.7771 12.0913C15.9278 11.4049 14.8308 11.6014 14.1643 12.3589Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  fileNormal: ({
    color = 'var(--color-neutral-550)',
    width = 20,
    height = width,
  }) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6668 9.16675H6.66683M8.3335 12.5001H6.66683M13.3335 5.83341H6.66683M16.6668 5.66675V14.3334C16.6668 15.7335 16.6668 16.4336 16.3943 16.9684C16.1547 17.4388 15.7722 17.8212 15.3018 18.0609C14.767 18.3334 14.067 18.3334 12.6668 18.3334H7.3335C5.93336 18.3334 5.2333 18.3334 4.69852 18.0609C4.22811 17.8212 3.84566 17.4388 3.60598 16.9684C3.3335 16.4336 3.3335 15.7335 3.3335 14.3334V5.66675C3.3335 4.26662 3.3335 3.56655 3.60598 3.03177C3.84566 2.56137 4.22811 2.17892 4.69852 1.93923C5.2333 1.66675 5.93336 1.66675 7.3335 1.66675H12.6668C14.067 1.66675 14.767 1.66675 15.3018 1.93923C15.7722 2.17892 16.1547 2.56137 16.3943 3.03177C16.6668 3.56655 16.6668 4.26662 16.6668 5.66675Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  folder: ({ color = 'var(--color-neutral-550)' }) => (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.08341 7.99992H4.90172C5.47272 7.99992 5.99472 8.32253 6.25008 8.83325C6.50544 9.34397 7.02744 9.66659 7.59844 9.66659H12.4017C12.9727 9.66659 13.4947 9.34397 13.7501 8.83325C14.0054 8.32253 14.5274 7.99992 15.0984 7.99992H17.9167M7.47222 1.33325H12.5279C13.4253 1.33325 13.874 1.33325 14.2702 1.46989C14.6205 1.59072 14.9396 1.78792 15.2043 2.04721C15.5037 2.34042 15.7043 2.74175 16.1057 3.5444L17.9111 7.15534C18.0686 7.47032 18.1474 7.62782 18.2029 7.79287C18.2522 7.93946 18.2878 8.09031 18.3093 8.24347C18.3334 8.41594 18.3334 8.59202 18.3334 8.94419V10.6666C18.3334 12.0667 18.3334 12.7668 18.0609 13.3016C17.8212 13.772 17.4388 14.1544 16.9684 14.3941C16.4336 14.6666 15.7335 14.6666 14.3334 14.6666H5.66675C4.26662 14.6666 3.56655 14.6666 3.03177 14.3941C2.56137 14.1544 2.17892 13.772 1.93923 13.3016C1.66675 12.7668 1.66675 12.0667 1.66675 10.6666V8.94419C1.66675 8.59202 1.66675 8.41594 1.69089 8.24347C1.71234 8.09031 1.74795 7.93946 1.79726 7.79287C1.8528 7.62782 1.93155 7.47032 2.08904 7.15534L3.89451 3.5444C4.29583 2.74174 4.4965 2.34042 4.79587 2.04721C5.06061 1.78792 5.37968 1.59072 5.72999 1.46989C6.12613 1.33325 6.57482 1.33325 7.47222 1.33325Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  game: ({ color = 'var(--color-neutral-550)' }) => (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.99979 6.16675H7.33312M5.66645 4.50008V7.83341M11.4998 7.00008H11.5081M13.9998 5.33341H14.0081M7.70725 1.16675H10.2923C12.4797 1.16675 13.5734 1.16675 14.4319 1.58127C15.1878 1.94625 15.8157 2.53099 16.2336 3.25891C16.7083 4.08566 16.7862 5.17659 16.9421 7.35843L17.1471 10.2288C17.2476 11.6363 16.1329 12.8334 14.7219 12.8334C14.0003 12.8334 13.316 12.5129 12.8541 11.9586L12.5415 11.5834C12.2555 11.2403 12.1125 11.0687 11.9497 10.93C11.6084 10.6394 11.1952 10.4459 10.7534 10.3697C10.5427 10.3334 10.3193 10.3334 9.87265 10.3334H8.12692C7.68023 10.3334 7.45688 10.3334 7.24615 10.3697C6.80437 10.4459 6.39119 10.6394 6.04988 10.93C5.88707 11.0687 5.74409 11.2403 5.45812 11.5834L5.14549 11.9586C4.68354 12.5129 3.99925 12.8334 3.27767 12.8334C1.86665 12.8334 0.751965 11.6363 0.852495 10.2288L1.05752 7.35843C1.21337 5.17659 1.29129 4.08566 1.76593 3.25891C2.18383 2.53099 2.81182 1.94625 3.56767 1.58127C4.42614 1.16675 5.51984 1.16675 7.70725 1.16675Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  gift: ({ color = 'var(--color-neutral-550)' }) => (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2857 4.97171V21.4288M11.2857 4.97171H7.64898C7.11313 4.97171 6.59924 4.75497 6.22033 4.36918C5.84143 3.9834 5.62857 3.46015 5.62857 2.91456C5.62857 2.36898 5.84143 1.84573 6.22033 1.45995C6.59924 1.07416 7.11313 0.857422 7.64898 0.857422C10.4776 0.857422 11.2857 4.97171 11.2857 4.97171ZM11.2857 4.97171H14.9224C15.4583 4.97171 15.9722 4.75497 16.3511 4.36918C16.73 3.9834 16.9429 3.46015 16.9429 2.91456C16.9429 2.36898 16.73 1.84573 16.3511 1.45995C15.9722 1.07416 15.4583 0.857422 14.9224 0.857422C12.0939 0.857422 11.2857 4.97171 11.2857 4.97171ZM19.5143 10.1146V18.1374C19.5143 19.2895 19.5143 19.8656 19.2901 20.3056C19.0928 20.6927 18.7781 21.0074 18.3911 21.2046C17.951 21.4288 17.375 21.4288 16.2229 21.4288L6.34857 21.4288C5.19646 21.4288 4.62041 21.4288 4.18036 21.2046C3.79329 21.0074 3.47858 20.6927 3.28136 20.3056C3.05714 19.8656 3.05714 19.2895 3.05714 18.1374V10.1146M1 6.61742L1 8.46885C1 9.0449 1 9.33293 1.11211 9.55295C1.21072 9.74649 1.36807 9.90384 1.56161 10.0025C1.78163 10.1146 2.06966 10.1146 2.64572 10.1146L19.9257 10.1146C20.5018 10.1146 20.7898 10.1146 21.0098 10.0025C21.2034 9.90384 21.3607 9.74649 21.4593 9.55295C21.5714 9.33293 21.5714 9.0449 21.5714 8.46885V6.61742C21.5714 6.04137 21.5714 5.75334 21.4593 5.53332C21.3607 5.33978 21.2034 5.18243 21.0098 5.08382C20.7898 4.97171 20.5018 4.97171 19.9257 4.97171L2.64571 4.97171C2.06966 4.97171 1.78163 4.97171 1.56161 5.08381C1.36807 5.18243 1.21072 5.33978 1.11211 5.53332C1 5.75334 1 6.04137 1 6.61742Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  groupChat: ({ color = 'var(--color-neutral-550)' }) => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.33341 12.5001L5.7707 15.0948C5.41324 15.4568 5.2345 15.6377 5.08087 15.6505C4.94759 15.6616 4.81709 15.608 4.73005 15.5064C4.62971 15.3894 4.62971 15.1351 4.62971 14.6264V13.3264C4.62971 12.87 4.25598 12.5398 3.80442 12.4737V12.4737C2.71153 12.3136 1.85323 11.4553 1.69314 10.3624C1.66675 10.1822 1.66675 9.96718 1.66675 9.53712V5.66675C1.66675 4.26662 1.66675 3.56655 1.93923 3.03177C2.17892 2.56137 2.56137 2.17892 3.03177 1.93923C3.56655 1.66675 4.26662 1.66675 5.66675 1.66675H11.8334C13.2335 1.66675 13.9336 1.66675 14.4684 1.93923C14.9388 2.17892 15.3212 2.56137 15.5609 3.03177C15.8334 3.56655 15.8334 4.26662 15.8334 5.66675V9.16675M15.8334 18.3334L14.0197 17.0725C13.7648 16.8952 13.6373 16.8066 13.4986 16.7438C13.3755 16.688 13.246 16.6474 13.1131 16.6229C12.9633 16.5953 12.808 16.5953 12.4975 16.5953H11.0001C10.0667 16.5953 9.59995 16.5953 9.24343 16.4137C8.92983 16.2539 8.67486 15.9989 8.51507 15.6853C8.33341 15.3288 8.33341 14.8621 8.33341 13.9287V11.8334C8.33341 10.9 8.33341 10.4333 8.51507 10.0768C8.67486 9.76316 8.92983 9.50819 9.24343 9.3484C9.59995 9.16675 10.0667 9.16675 11.0001 9.16675H15.6667C16.6002 9.16675 17.0669 9.16675 17.4234 9.3484C17.737 9.50819 17.992 9.76316 18.1518 10.0768C18.3334 10.4333 18.3334 10.9 18.3334 11.8334V14.0953C18.3334 14.8719 18.3334 15.2602 18.2065 15.5665C18.0374 15.9748 17.7129 16.2993 17.3046 16.4685C16.9983 16.5953 16.61 16.5953 15.8334 16.5953V18.3334Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  groupUser: ({ color = 'var(--color-primary)' }) => (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1666 1.31184C11.1544 1.80274 11.8333 2.82209 11.8333 4C11.8333 5.17791 11.1544 6.19726 10.1666 6.68816M11.4999 10.1776C12.5076 10.6336 13.4149 11.3767 14.1666 12.3333M0.833252 12.3333C2.13091 10.6817 3.89271 9.66667 5.83325 9.66667C7.7738 9.66667 9.53559 10.6817 10.8333 12.3333M8.83325 4C8.83325 5.65685 7.49011 7 5.83325 7C4.1764 7 2.83325 5.65685 2.83325 4C2.83325 2.34315 4.1764 1 5.83325 1C7.49011 1 8.83325 2.34315 8.83325 4Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  heart: ({ color = 'white' }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M7.82703 2C9.39246 2 10.4443 3.49 10.4443 4.88C10.4443 7.695 6.07888 10 5.99986 10C5.92085 10 1.55542 7.695 1.55542 4.88C1.55542 3.49 2.60727 2 4.1727 2C5.07147 2 5.65912 2.455 5.99986 2.855C6.34061 2.455 6.92826 2 7.82703 2Z"
        stroke={color}
        strokeWidth="0.888889"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  heartActive: ({ color = 'var(--color-error)' }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="10"
      viewBox="0 0 10 8"
      fill="none"
    >
      <path
        d="M6.82733 0C8.39276 0 9.44461 1.49 9.44461 2.88C9.44461 5.695 5.07918 8 5.00017 8C4.92116 8 0.555725 5.695 0.555725 2.88C0.555725 1.49 1.60758 0 3.17301 0C4.07177 0 4.65943 0.455 5.00017 0.855C5.34091 0.455 5.92857 0 6.82733 0Z"
        fill={color}
      />
    </svg>
  ),
  heartInActive: ({ color = 'gray' }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 10 8"
      fill="none"
    >
      <path
        d="M6.82733 0C8.39276 0 9.44461 1.49 9.44461 2.88C9.44461 5.695 5.07918 8 5.00017 8C4.92116 8 0.555725 5.695 0.555725 2.88C0.555725 1.49 1.60758 0 3.17301 0C4.07177 0 4.65943 0.455 5.00017 0.855C5.34091 0.455 5.92857 0 6.82733 0Z"
        stroke={color}
      />
    </svg>
  ),
  headphone: ({ color = 'white' }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.6523 16.7013H19.4532C18.8218 16.7013 18.2988 16.2306 18.2988 15.6495V8.12408C18.2988 7.54878 18.8154 7.07227 19.4532 7.07227H20.6523C21.2837 7.07227 21.8067 7.54297 21.8067 8.12408V15.6495C21.8067 16.2306 21.2837 16.7013 20.6523 16.7013Z"
        fill={color}
      />
      <path
        d="M22.0996 8.12402V15.6495C23.1839 15.2892 24.0002 13.7434 24.0002 11.8896C24.0002 10.0359 23.1839 8.48431 22.0996 8.12402Z"
        fill={color}
      />
      <path
        d="M3.34776 7.07812H4.5468C5.17821 7.07812 5.7012 7.54883 5.7012 8.12994V15.6554C5.7012 16.2307 5.18459 16.7072 4.5468 16.7072H3.34776C2.71635 16.7072 2.19336 16.2365 2.19336 15.6554V8.12413C2.19336 7.54883 2.71635 7.07812 3.34776 7.07812Z"
        fill={color}
      />
      <path
        d="M1.90061 15.6495V8.12402C0.81637 8.48431 0 10.0359 0 11.8896C0 13.7434 0.81637 15.2892 1.90061 15.6495Z"
        fill={color}
      />
      <path
        d="M3.64116 9.32107C3.63478 9.16998 3.6284 9.01889 3.6284 8.86781C3.6284 4.55013 7.3212 1.05182 11.875 1.05182C16.4288 1.05182 20.1216 4.55013 20.1216 8.86781C20.1216 9.01889 20.1152 9.16998 20.1089 9.32107H21.1293C21.1421 9.14674 21.1485 8.96659 21.1485 8.78645C21.1485 3.93414 16.9965 0 11.875 0C6.75357 0 2.60156 3.93414 2.60156 8.78645C2.60156 8.96659 2.60794 9.14674 2.6207 9.32107H3.64116Z"
        fill={color}
      />
      <path
        d="M12.3037 22.4833C12.2336 22.4833 12.1698 22.4833 12.0996 22.4833C7.54583 22.3962 3.9487 19.2117 4.05712 15.3705C4.0635 15.2368 4.06988 15.0974 4.08263 14.9637L3.06217 14.9463C3.04941 15.1032 3.03666 15.2601 3.03028 15.4228C2.90272 19.7405 6.9463 23.3201 12.0678 23.4189C12.1443 23.4189 12.2208 23.4189 12.2974 23.4189V22.4833H12.3037Z"
        fill={color}
      />
      <path
        d="M9.93764 24.0003H12.4378C13.0182 24.0003 13.4965 23.5645 13.4965 23.0357V22.4139C13.4965 21.8851 13.0182 21.4492 12.4378 21.4492H9.93764C9.35725 21.4492 8.87891 21.8851 8.87891 22.4139V23.0357C8.87891 23.5703 9.35725 24.0003 9.93764 24.0003Z"
        fill={color}
      />
    </svg>
  ),
  home: ({ color = 'white' }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M8.125 17H16.125M11.1427 2.76401L4.36039 8.03914C3.90702 8.39176 3.68034 8.56807 3.51703 8.78887C3.37237 8.98446 3.2646 9.2048 3.19903 9.43907C3.125 9.70353 3.125 9.99071 3.125 10.5651V17.8C3.125 18.9201 3.125 19.4802 3.34299 19.908C3.53473 20.2843 3.84069 20.5903 4.21702 20.782C4.64484 21 5.20489 21 6.325 21H17.925C19.0451 21 19.6052 21 20.033 20.782C20.4093 20.5903 20.7153 20.2843 20.907 19.908C21.125 19.4802 21.125 18.9201 21.125 17.8V10.5651C21.125 9.99071 21.125 9.70353 21.051 9.43907C20.9854 9.2048 20.8776 8.98446 20.733 8.78887C20.5697 8.56807 20.343 8.39176 19.8896 8.03914L13.1073 2.76401C12.756 2.49076 12.5803 2.35413 12.3863 2.30162C12.2152 2.25528 12.0348 2.25528 11.8637 2.30162C11.6697 2.35413 11.494 2.49076 11.1427 2.76401Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  image: ({ color = 'var(--color-neutral)' }) => (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.27209 20.7279L10.8686 14.1314C11.2646 13.7354 11.4627 13.5373 11.691 13.4632C11.8918 13.3979 12.1082 13.3979 12.309 13.4632C12.5373 13.5373 12.7354 13.7354 13.1314 14.1314L19.6839 20.6839M14 15L16.8686 12.1314C17.2646 11.7354 17.4627 11.5373 17.691 11.4632C17.8918 11.3979 18.1082 11.3979 18.309 11.4632C18.5373 11.5373 18.7354 11.7354 19.1314 12.1314L22 15M10 9C10 10.1046 9.10457 11 8 11C6.89543 11 6 10.1046 6 9C6 7.89543 6.89543 7 8 7C9.10457 7 10 7.89543 10 9ZM6.8 21H17.2C18.8802 21 19.7202 21 20.362 20.673C20.9265 20.3854 21.3854 19.9265 21.673 19.362C22 18.7202 22 17.8802 22 16.2V7.8C22 6.11984 22 5.27976 21.673 4.63803C21.3854 4.07354 20.9265 3.6146 20.362 3.32698C19.7202 3 18.8802 3 17.2 3H6.8C5.11984 3 4.27976 3 3.63803 3.32698C3.07354 3.6146 2.6146 4.07354 2.32698 4.63803C2 5.27976 2 6.11984 2 7.8V16.2C2 17.8802 2 18.7202 2.32698 19.362C2.6146 19.9265 3.07354 20.3854 3.63803 20.673C4.27976 21 5.11984 21 6.8 21Z"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  info: ({ color = 'var(--color-neutral-550)' }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_40_2227)">
        <path
          d="M10.0001 13.3334V10.0001M10.0001 6.66675H10.0084M1.66675 7.10237V12.8978C1.66675 13.1016 1.66675 13.2035 1.68977 13.2994C1.71019 13.3845 1.74386 13.4658 1.78955 13.5403C1.84108 13.6244 1.91315 13.6965 2.05727 13.8406L6.15956 17.9429C6.30368 18.087 6.37575 18.1591 6.45984 18.2106C6.5344 18.2563 6.61569 18.29 6.70072 18.3104C6.79663 18.3334 6.89854 18.3334 7.10237 18.3334H12.8978C13.1016 18.3334 13.2035 18.3334 13.2994 18.3104C13.3845 18.29 13.4658 18.2563 13.5403 18.2106C13.6244 18.1591 13.6965 18.087 13.8406 17.9429L17.9429 13.8406C18.087 13.6965 18.1591 13.6244 18.2106 13.5403C18.2563 13.4658 18.29 13.3845 18.3104 13.2994C18.3334 13.2035 18.3334 13.1016 18.3334 12.8978V7.10237C18.3334 6.89854 18.3334 6.79663 18.3104 6.70072C18.29 6.61569 18.2563 6.5344 18.2106 6.45984C18.1591 6.37575 18.087 6.30368 17.9429 6.15956L13.8406 2.05727C13.6965 1.91315 13.6244 1.84108 13.5403 1.78955C13.4658 1.74386 13.3845 1.71019 13.2994 1.68977C13.2035 1.66675 13.1016 1.66675 12.8978 1.66675H7.10237C6.89854 1.66675 6.79663 1.66675 6.70072 1.68977C6.61569 1.71019 6.5344 1.74386 6.45984 1.78955C6.37575 1.84108 6.30368 1.91315 6.15956 2.05727L2.05727 6.15956C1.91315 6.30368 1.84108 6.37575 1.78955 6.45984C1.74386 6.5344 1.71019 6.61569 1.68977 6.70072C1.66675 6.79663 1.66675 6.89854 1.66675 7.10237Z"
          stroke={color}
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_40_2227">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  light: ({ color = 'var(--color-neutral-550)' }) => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.33317 14.7156V16.6667C8.33317 17.5872 9.07936 18.3334 9.99984 18.3334C10.9203 18.3334 11.6665 17.5872 11.6665 16.6667V14.7156M9.99984 1.66675V2.50008M2.49984 10.0001H1.6665M4.58317 4.58341L4.08309 4.08333M15.4165 4.58341L15.9167 4.08333M18.3332 10.0001H17.4998M14.9998 10.0001C14.9998 12.7615 12.7613 15.0001 9.99984 15.0001C7.23841 15.0001 4.99984 12.7615 4.99984 10.0001C4.99984 7.23866 7.23841 5.00008 9.99984 5.00008C12.7613 5.00008 14.9998 7.23866 14.9998 10.0001Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  location: ({ color = 'var(--color-neutral)' }) => (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 12.5C13.6569 12.5 15 11.1569 15 9.5C15 7.84315 13.6569 6.5 12 6.5C10.3431 6.5 9 7.84315 9 9.5C9 11.1569 10.3431 12.5 12 12.5Z"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 22C14 18 20 15.4183 20 10C20 5.58172 16.4183 2 12 2C7.58172 2 4 5.58172 4 10C4 15.4183 10 18 12 22Z"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  logOut: ({ color = 'var(--color-error)' }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M13.3333 14.1667L17.5 10M17.5 10L13.3333 5.83333M17.5 10H7.5M7.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5H7.5"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  lock: ({ color = 'white' }) => (
    <svg
      width="32"
      height="37"
      viewBox="0 0 32 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2857 10.4062V13.875H21.7143V10.4062C21.7143 7.21211 19.1571 4.625 16 4.625C12.8429 4.625 10.2857 7.21211 10.2857 10.4062ZM5.71429 13.875V10.4062C5.71429 4.66113 10.3214 0 16 0C21.6786 0 26.2857 4.66113 26.2857 10.4062V13.875H27.4286C29.95 13.875 32 15.949 32 18.5V32.375C32 34.926 29.95 37 27.4286 37H4.57143C2.05 37 0 34.926 0 32.375V18.5C0 15.949 2.05 13.875 4.57143 13.875H5.71429Z"
        fill={color}
      />
    </svg>
  ),
  message: ({ color = 'white' }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M6.96936 11.2288C6.90721 10.8282 6.87496 10.4179 6.87496 10C6.87496 5.58172 10.4802 2 14.9276 2C19.3749 2 22.9802 5.58172 22.9802 10C22.9802 10.9981 22.7963 11.9535 22.4602 12.8345C22.3904 13.0175 22.3554 13.109 22.3396 13.1804C22.3239 13.2512 22.3178 13.301 22.3161 13.3735C22.3144 13.4466 22.3243 13.5272 22.3442 13.6883L22.7467 16.9585C22.7903 17.3125 22.8121 17.4895 22.7532 17.6182C22.7016 17.731 22.61 17.8205 22.4961 17.8695C22.3661 17.9254 22.1896 17.8995 21.8367 17.8478L18.6515 17.3809C18.4851 17.3565 18.402 17.3443 18.3262 17.3448C18.2513 17.3452 18.1995 17.3507 18.1261 17.3661C18.052 17.3817 17.9573 17.4172 17.768 17.4881C16.8847 17.819 15.9274 18 14.9276 18C14.5094 18 14.0987 17.9683 13.6977 17.9073M8.50658 22C11.4715 22 13.875 19.5376 13.875 16.5C13.875 13.4624 11.4715 11 8.50658 11C5.54168 11 3.13816 13.4624 3.13816 16.5C3.13816 17.1106 3.23528 17.6979 3.41455 18.2467C3.49033 18.4787 3.52822 18.5947 3.54066 18.6739C3.55364 18.7567 3.55591 18.8031 3.55108 18.8867C3.54645 18.9668 3.52641 19.0573 3.48634 19.2383L2.875 22L5.8698 21.591C6.03327 21.5687 6.115 21.5575 6.18637 21.558C6.26152 21.5585 6.30141 21.5626 6.37511 21.5773C6.4451 21.5912 6.54916 21.6279 6.75727 21.7014C7.30559 21.8949 7.89411 22 8.50658 22Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  minus: ({ color = 'var(--color-primary-600)' }) => (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 6H9.5"
        stroke={color}
        strokeWidth="1.66666"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  minusCircle: ({ color = 'black' }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  money: ({ color = 'var(--color-success)', width = 20, height = width }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M4.99999 9.16668V12.5M15 7.50001V10.8333M14.1667 3.33334C16.2072 3.33334 17.3109 3.64564 17.8601 3.88788C17.9332 3.92014 17.9698 3.93627 18.0753 4.03699C18.1386 4.09736 18.2541 4.2745 18.2838 4.35675C18.3333 4.49396 18.3333 4.56896 18.3333 4.71896V13.676C18.3333 14.4333 18.3333 14.8119 18.2198 15.0066C18.1042 15.2045 17.9928 15.2966 17.7766 15.3727C17.564 15.4475 17.135 15.365 16.2768 15.2001C15.6761 15.0847 14.9638 15 14.1667 15C11.6667 15 9.16666 16.6667 5.83332 16.6667C3.79273 16.6667 2.68905 16.3544 2.13989 16.1121C2.06676 16.0799 2.03019 16.0638 1.92466 15.963C1.8614 15.9027 1.74592 15.7255 1.71621 15.6433C1.66666 15.5061 1.66666 15.4311 1.66666 15.2811L1.66666 6.32405C1.66666 5.56675 1.66666 5.1881 1.78022 4.99346C1.89574 4.79548 2.00715 4.70344 2.22337 4.62735C2.43594 4.55255 2.86502 4.635 3.72318 4.79988C4.32384 4.91529 5.03622 5.00001 5.83332 5.00001C8.33332 5.00001 10.8333 3.33334 14.1667 3.33334ZM12.0833 10C12.0833 11.1506 11.1506 12.0833 9.99999 12.0833C8.8494 12.0833 7.91666 11.1506 7.91666 10C7.91666 8.84942 8.8494 7.91668 9.99999 7.91668C11.1506 7.91668 12.0833 8.84942 12.0833 10Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  paper: ({ color = 'var(--color-neutral-550)' }) => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6668 1.89136V5.33348C11.6668 5.80018 11.6668 6.03354 11.7577 6.2118C11.8376 6.3686 11.965 6.49608 12.1218 6.57598C12.3001 6.66681 12.5335 6.66681 13.0002 6.66681H16.4423M13.3335 10.8334H6.66683M13.3335 14.1667H6.66683M8.3335 7.50008H6.66683M11.6668 1.66675H7.3335C5.93336 1.66675 5.2333 1.66675 4.69852 1.93923C4.22811 2.17892 3.84566 2.56137 3.60598 3.03177C3.3335 3.56655 3.3335 4.26662 3.3335 5.66675V14.3334C3.3335 15.7335 3.3335 16.4336 3.60598 16.9684C3.84566 17.4388 4.22811 17.8212 4.69852 18.0609C5.2333 18.3334 5.93336 18.3334 7.3335 18.3334H12.6668C14.067 18.3334 14.767 18.3334 15.3018 18.0609C15.7722 17.8212 16.1547 17.4388 16.3943 16.9684C16.6668 16.4336 16.6668 15.7335 16.6668 14.3334V6.66675L11.6668 1.66675Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  pin: ({ color = 'black' }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.3767 15.6163L2.71985 21.2732M11.6944 6.64181L10.1335 8.2027C10.0062 8.33003 9.94252 8.39369 9.86999 8.44427C9.80561 8.48917 9.73616 8.52634 9.66309 8.555C9.58077 8.58729 9.49249 8.60495 9.31592 8.64026L5.65145 9.37315C4.69915 9.56361 4.223 9.65884 4.00024 9.9099C3.80617 10.1286 3.71755 10.4213 3.75771 10.7109C3.8038 11.0434 4.14715 11.3867 4.83387 12.0735L11.9196 19.1592C12.6063 19.8459 12.9497 20.1893 13.2821 20.2354C13.5718 20.2755 13.8645 20.1869 14.0832 19.9928C14.3342 19.7701 14.4294 19.2939 14.6199 18.3416L15.3528 14.6771C15.3881 14.5006 15.4058 14.4123 15.4381 14.33C15.4667 14.2569 15.5039 14.1875 15.5488 14.1231C15.5994 14.0505 15.663 13.9869 15.7904 13.8596L17.3512 12.2987C17.4326 12.2173 17.4734 12.1766 17.5181 12.141C17.5578 12.1095 17.5999 12.081 17.644 12.0558C17.6936 12.0274 17.7465 12.0048 17.8523 11.9594L20.3467 10.8904C21.0744 10.5785 21.4383 10.4226 21.6035 10.1706C21.7481 9.95025 21.7998 9.68175 21.7474 9.42348C21.6875 9.12813 21.4076 8.84822 20.8478 8.28839L15.7047 3.14526C15.1448 2.58543 14.8649 2.30552 14.5696 2.24565C14.3113 2.19329 14.0428 2.245 13.8225 2.38953C13.5705 2.55481 13.4145 2.91866 13.1027 3.64636L12.0337 6.14071C11.9883 6.24653 11.9656 6.29944 11.9373 6.34905C11.9121 6.39313 11.8836 6.43522 11.852 6.47496C11.8165 6.51971 11.7758 6.56041 11.6944 6.64181Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={color}
      />
    </svg>
  ),
  pinSmall: ({ color = 'black' }) => (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.79223 5.20548L0.906616 7.09109M3.89813 2.21398L3.37783 2.73427C3.33539 2.77672 3.31417 2.79794 3.29 2.8148C3.26854 2.82977 3.24539 2.84215 3.22103 2.85171C3.19359 2.86247 3.16416 2.86836 3.10531 2.88013L1.88382 3.12442C1.56638 3.18791 1.40767 3.21966 1.33341 3.30334C1.26872 3.37624 1.23918 3.47381 1.25257 3.57035C1.26793 3.68117 1.38238 3.79562 1.61129 4.02452L3.9732 6.38644C4.20211 6.61534 4.31656 6.7298 4.42738 6.74516C4.52392 6.75854 4.62148 6.729 4.69439 6.66431C4.77807 6.59006 4.80982 6.43134 4.8733 6.11391L5.1176 4.89242C5.12937 4.83357 5.13526 4.80414 5.14602 4.7767C5.15557 4.75234 5.16796 4.72919 5.18293 4.70773C5.19979 4.68355 5.22101 4.66233 5.26345 4.61989L5.78375 4.0996C5.81088 4.07246 5.82445 4.05889 5.83937 4.04705C5.85261 4.03653 5.86664 4.02703 5.88134 4.01863C5.89787 4.00918 5.91551 4.00163 5.95078 3.98651L6.78223 3.63017C7.0248 3.52622 7.14608 3.47424 7.20118 3.39024C7.24935 3.31679 7.26659 3.22729 7.24914 3.1412C7.22918 3.04275 7.13588 2.94945 6.94927 2.76284L5.23489 1.04846C5.04828 0.861851 4.95498 0.768547 4.85653 0.74859C4.77044 0.731137 4.68094 0.748374 4.60748 0.796551C4.52349 0.851643 4.47151 0.972927 4.36755 1.21549L4.01122 2.04695C3.9961 2.08222 3.98854 2.09985 3.97909 2.11639C3.9707 2.13108 3.9612 2.14511 3.95068 2.15836C3.93883 2.17328 3.92527 2.18684 3.89813 2.21398Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  plus: ({ color = 'white' }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M9.99935 4.1665V15.8332M4.16602 9.99984H15.8327"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  plusCircle: ({ color = 'black' }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 8V16M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  profileCheck: ({ color = 'var(--color-neutral-550)' }) => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3333 4.16699L15 5.83366L18.3333 2.50033M18.3333 10.0003V14.3337C18.3333 15.7338 18.3333 16.4339 18.0609 16.9686C17.8212 17.439 17.4387 17.8215 16.9683 18.0612C16.4335 18.3337 15.7335 18.3337 14.3333 18.3337H5.66667C4.26654 18.3337 3.56647 18.3337 3.0317 18.0612C2.56129 17.8215 2.17884 17.439 1.93916 16.9686C1.66667 16.4339 1.66667 15.7338 1.66667 14.3337V5.66699C1.66667 4.26686 1.66667 3.5668 1.93916 3.03202C2.17884 2.56161 2.56129 2.17916 3.0317 1.93948C3.56647 1.66699 4.26654 1.66699 5.66667 1.66699H10M1.78793 16.6056C2.17888 15.1992 3.46881 14.167 4.99981 14.167H10.8331C11.6076 14.167 11.9948 14.167 12.3168 14.231C13.6391 14.4941 14.6727 15.5277 14.9358 16.85C14.9998 17.172 14.9998 17.5592 14.9998 18.3337M11.6667 7.91699C11.6667 9.75794 10.1743 11.2503 8.33334 11.2503C6.49239 11.2503 5.00001 9.75794 5.00001 7.91699C5.00001 6.07604 6.49239 4.58366 8.33334 4.58366C10.1743 4.58366 11.6667 6.07604 11.6667 7.91699Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  question: ({ color = 'var(--color-neutral-550)' }) => (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.33333 6.0852C7.48016 5.6678 7.76998 5.31584 8.15144 5.09165C8.53291 4.86745 8.98141 4.7855 9.41751 4.86031C9.85361 4.93511 10.2492 5.16184 10.5341 5.50034C10.8191 5.83884 10.975 6.26726 10.9744 6.70973C10.9744 7.9588 9.10077 8.58333 9.10077 8.58333M9.12492 11.0833H9.13325M7.25 15L8.46667 16.6222C8.6476 16.8635 8.73807 16.9841 8.84897 17.0272C8.94611 17.065 9.05389 17.065 9.15103 17.0272C9.26193 16.9841 9.3524 16.8635 9.53333 16.6222L10.75 15C10.9943 14.6743 11.1164 14.5114 11.2654 14.3871C11.4641 14.2213 11.6986 14.104 11.9504 14.0446C12.1393 14 12.3429 14 12.75 14C13.9149 14 14.4973 14 14.9567 13.8097C15.5693 13.556 16.056 13.0693 16.3097 12.4567C16.5 11.9973 16.5 11.4149 16.5 10.25V5.5C16.5 4.09987 16.5 3.3998 16.2275 2.86502C15.9878 2.39462 15.6054 2.01217 15.135 1.77248C14.6002 1.5 13.9001 1.5 12.5 1.5H5.5C4.09987 1.5 3.3998 1.5 2.86502 1.77248C2.39462 2.01217 2.01217 2.39462 1.77248 2.86502C1.5 3.3998 1.5 4.09987 1.5 5.5V10.25C1.5 11.4149 1.5 11.9973 1.6903 12.4567C1.94404 13.0693 2.43072 13.556 3.04329 13.8097C3.50272 14 4.08515 14 5.25 14C5.65715 14 5.86072 14 6.04959 14.0446C6.30141 14.104 6.53593 14.2213 6.73458 14.3871C6.88357 14.5114 7.00571 14.6743 7.25 15Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  refresh: ({ color = 'var(--color-neutral)' }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 10C2 10 4.00498 7.26822 5.63384 5.63824C7.26269 4.00827 9.5136 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.89691 21 4.43511 18.2543 3.35177 14.5M2 10V4M2 10H8"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  search: ({ color = 'white' }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M21.375 21L17.025 16.65M19.375 11C19.375 15.4183 15.7933 19 11.375 19C6.95672 19 3.375 15.4183 3.375 11C3.375 6.58172 6.95672 3 11.375 3C15.7933 3 19.375 6.58172 19.375 11Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  searchInput: ({ color = 'var(--color-neutral-300)' }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  sendMessage: ({ color = 'currentColor' }) => (
    <svg
      className="w-6 h-6 rotate-90"
      fill={color}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
    </svg>
  ),
  shield: ({ color = 'var(--color-neutral-550)' }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M9.41826 18.0123C9.60275 18.1199 9.695 18.1737 9.82519 18.2017C9.92622 18.2233 10.0736 18.2233 10.1747 18.2017C10.3048 18.1737 10.3971 18.1199 10.5816 18.0123C12.205 17.0652 16.6666 14.0903 16.6666 9.99989V6.83323C16.6666 5.93831 16.6666 5.49086 16.5286 5.17323C16.3884 4.85039 16.2487 4.67865 15.9612 4.47565C15.6783 4.27592 15.1238 4.16058 14.0147 3.92991C12.7923 3.67567 11.8535 3.21659 10.9952 2.55266C10.5837 2.23428 10.3779 2.07509 10.2169 2.03168C10.047 1.98586 9.95289 1.98586 9.78297 2.03168C9.62194 2.07509 9.41616 2.23428 9.0046 2.55266C8.14635 3.21659 7.20758 3.67568 5.98517 3.92991C4.87606 4.16058 4.32151 4.27592 4.03862 4.47565C3.7511 4.67865 3.61141 4.85039 3.4712 5.17323C3.33325 5.49086 3.33325 5.93831 3.33325 6.83323V9.99989C3.33325 14.0903 7.79489 17.0652 9.41826 18.0123Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  star: ({ color = 'var(--color-primary)' }) => (
    <>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.3821 2.15534L11.79 4.9943C11.9819 5.3895 12.4939 5.76856 12.9258 5.84115L15.4775 6.2686C17.1094 6.54282 17.4933 7.73647 16.3175 8.914L14.3337 10.9142C13.9977 11.2529 13.8137 11.9062 13.9177 12.374L14.4857 14.85C14.9336 16.8098 13.9017 17.568 12.1819 16.5437L9.79017 15.1162C9.35822 14.8581 8.6463 14.8581 8.20635 15.1162L5.81461 16.5437C4.1028 17.568 3.06292 16.8018 3.51087 14.85L4.07881 12.374C4.18279 11.9062 3.99881 11.2529 3.66285 10.9142L1.67907 8.914C0.5112 7.73647 0.887159 6.54282 2.51898 6.2686L5.0707 5.84115C5.49465 5.76856 6.00659 5.3895 6.19857 4.9943L7.60642 2.15534C8.37433 0.614886 9.62219 0.614886 10.3821 2.15534Z"
          fill={color}
          stroke="var(--color-primary)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  ),
  sticker: ({ color = 'var(--color-neutral)' }) => (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 8V9.5M16.5 8V9.5M11 12.6001C11.8 12.6001 12.5 11.9001 12.5 11.1001V8M15.2002 15.2C13.4002 17 10.5002 17 8.7002 15.2M3 7.8L3 16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V7.8C21 6.11984 21 5.27977 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3L7.8 3C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8Z"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  teco: ({ color = 'black', width = 20, height = width }) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10.5" r="10" fill={color} />
      <path
        d="M10.0518 16.5C9.8053 16.5 9.55391 16.4482 9.2976 16.3446C9.05114 16.2316 8.92791 16.0527 8.92791 15.8079V9.19774C8.92791 8.89642 9.00678 8.68927 9.16451 8.57627C9.32224 8.46328 9.4504 8.39266 9.54898 8.36441H6.69501C6.45841 8.36441 6.28096 8.26554 6.16266 8.0678C6.05422 7.86064 6 7.64878 6 7.4322C6 7.1968 6.05915 6.98493 6.17745 6.79661C6.29575 6.59887 6.46827 6.5 6.69501 6.5H13.305C13.5317 6.5 13.7043 6.59887 13.8226 6.79661C13.9408 6.98493 14 7.1968 14 7.4322C14 7.64878 13.9408 7.86064 13.8226 8.0678C13.7141 8.26554 13.5416 8.36441 13.305 8.36441H11.0573V15.8079C11.0573 16.0433 10.9538 16.2175 10.7468 16.3305C10.5496 16.4435 10.3179 16.5 10.0518 16.5Z"
        fill="white"
      />
    </svg>
  ),
  trash: ({ color = 'var(--color-neutral)' }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M13.3333 5.00033V4.33366C13.3333 3.40024 13.3333 2.93353 13.1517 2.57701C12.9919 2.2634 12.7369 2.00844 12.4233 1.84865C12.0668 1.66699 11.6001 1.66699 10.6667 1.66699H9.33333C8.39991 1.66699 7.9332 1.66699 7.57668 1.84865C7.26308 2.00844 7.00811 2.2634 6.84832 2.57701C6.66667 2.93353 6.66667 3.40024 6.66667 4.33366V5.00033M8.33333 9.58366V13.7503M11.6667 9.58366V13.7503M2.5 5.00033H17.5M15.8333 5.00033V14.3337C15.8333 15.7338 15.8333 16.4339 15.5608 16.9686C15.3212 17.439 14.9387 17.8215 14.4683 18.0612C13.9335 18.3337 13.2335 18.3337 11.8333 18.3337H8.16667C6.76654 18.3337 6.06647 18.3337 5.53169 18.0612C5.06129 17.8215 4.67883 17.439 4.43915 16.9686C4.16667 16.4339 4.16667 15.7338 4.16667 14.3337V5.00033"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  user: ({ color = 'white' }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M3.625 20C5.96079 17.5226 9.13202 16 12.625 16C16.118 16 19.2892 17.5226 21.625 20M17.125 7.5C17.125 9.98528 15.1103 12 12.625 12C10.1397 12 8.125 9.98528 8.125 7.5C8.125 5.01472 10.1397 3 12.625 3C15.1103 3 17.125 5.01472 17.125 7.5Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  userCheck: ({ color = 'var(--color-neutral-550)' }) => (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3334 14L15.0001 15.6667L18.3334 12.3333M10.0001 11.5H6.66675C5.11361 11.5 4.33704 11.5 3.72447 11.7537C2.90771 12.092 2.2588 12.741 1.92048 13.5577C1.66675 14.1703 1.66675 14.9469 1.66675 16.5M12.9167 1.7423C14.1383 2.23679 15.0001 3.43443 15.0001 4.83333C15.0001 6.23224 14.1383 7.42988 12.9167 7.92437M11.2501 4.83333C11.2501 6.67428 9.7577 8.16667 7.91675 8.16667C6.0758 8.16667 4.58341 6.67428 4.58341 4.83333C4.58341 2.99238 6.0758 1.5 7.91675 1.5C9.7577 1.5 11.2501 2.99238 11.2501 4.83333Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  unpin: ({ color = 'black' }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.37658 15.6163L2.71973 21.2732M15.3527 14.6772L14.6198 18.3416C14.4293 19.2939 14.3341 19.7701 14.083 19.9929C13.8643 20.1869 13.5716 20.2755 13.282 20.2354C12.9496 20.1893 12.6062 19.8459 11.9195 19.1592L4.83375 12.0735C4.14703 11.3868 3.80367 11.0434 3.75758 10.711C3.71743 10.4213 3.80605 10.1286 4.00012 9.90993C4.22288 9.65888 4.69903 9.56365 5.65133 9.37319L9.3158 8.64029C9.49236 8.60498 9.58065 8.58732 9.66297 8.55503C9.73604 8.52637 9.80548 8.48921 9.86986 8.44431V8.44431C10.1431 8.25375 10.1927 8.19799 10.425 8.43668C15.0277 13.1645 11.2825 9.28956 15.5327 13.5984C15.6718 13.7394 15.662 13.9606 15.5487 14.1231V14.1231C15.5038 14.1875 15.4666 14.2569 15.4379 14.33C15.4056 14.4123 15.388 14.5006 15.3527 14.6772Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 4L20 18"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M11.8701 6.47924C11.9506 6.3779 12.0011 6.2632 12.0517 6.14499L13.1207 3.65063C13.4326 2.92293 13.5885 2.55908 13.8405 2.3938C14.0609 2.24927 14.3294 2.19756 14.5877 2.24992C14.883 2.30979 15.1629 2.5897 15.7227 3.14953L20.8659 8.29267C21.4257 8.85249 21.7056 9.13241 21.7655 9.42775C21.8178 9.68602 21.7661 9.95452 21.6216 10.1749C21.4563 10.4269 21.0925 10.5828 20.3648 10.8947L17.8704 11.9637C17.7646 12.009 17.7117 12.0317 17.6621 12.06"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  wifi: ({ color = 'var(--color-neutral-550)' }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M3.33325 9.16659C5.32238 9.16659 7.23003 9.95676 8.63655 11.3633C10.0431 12.7698 10.8333 14.6775 10.8333 16.6666M3.33325 3.33325C6.86947 3.33325 10.2609 4.73801 12.7613 7.23849C15.2618 9.73898 16.6666 13.1304 16.6666 16.6666M4.99992 15.8333C4.99992 16.2935 4.62682 16.6666 4.16659 16.6666C3.70635 16.6666 3.33325 16.2935 3.33325 15.8333C3.33325 15.373 3.70635 14.9999 4.16659 14.9999C4.62682 14.9999 4.99992 15.373 4.99992 15.8333Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  warning: ({ color = 'var(--color-warning)' }) => (
    <svg
      width="128"
      height="128"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_12290_110)">
        <path
          d="M63.9987 47.9999V69.3333M63.9987 90.6666H64.052M56.6137 20.7558L12.7477 96.5245C10.3146 100.727 9.09802 102.828 9.27782 104.553C9.43465 106.057 10.2228 107.424 11.446 108.314C12.8484 109.333 15.2765 109.333 20.1326 109.333H107.865C112.721 109.333 115.149 109.333 116.551 108.314C117.775 107.424 118.563 106.057 118.72 104.553C118.899 102.828 117.683 100.727 115.25 96.5244L71.3836 20.7558C68.9593 16.5683 67.7471 14.4745 66.1656 13.7713C64.7861 13.1579 63.2113 13.1579 61.8318 13.7713C60.2503 14.4745 59.0381 16.5683 56.6137 20.7558Z"
          stroke={color}
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M63.9987 47.9999V69.3333M63.9987 90.6666H64.052M56.6137 20.7558L12.7477 96.5245C10.3146 100.727 9.09802 102.828 9.27782 104.553C9.43465 106.057 10.2228 107.424 11.446 108.314C12.8484 109.333 15.2765 109.333 20.1326 109.333H107.865C112.721 109.333 115.149 109.333 116.551 108.314C117.775 107.424 118.563 106.057 118.72 104.553C118.899 102.828 117.683 100.727 115.25 96.5244L71.3836 20.7558C68.9593 16.5683 67.7471 14.4745 66.1656 13.7713C64.7861 13.1579 63.2113 13.1579 61.8318 13.7713C60.2503 14.4745 59.0381 16.5683 56.6137 20.7558Z"
          stroke={color}
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_12290_110"
          x="-4"
          y="0"
          width="136"
          height="136"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12290_110"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12290_110"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  ),
};

export default icons;
