import Text from 'components/Text';
import { DATE_TIME_FORMAT, routes } from '../../constants';
import {
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { EUserProvider, TPostDetail, TUpsertPostLike } from 'types';
import { formatDate } from 'utils';
import { Avatar, Icon, ModalGift, PostGallery } from 'components';
import { useNavigate } from 'react-router-dom';
import { usePostStore, useUserStore } from 'store';
import { useUser } from 'hooks';
import { useMutation } from '@tanstack/react-query';
import { createPostLike, deletePostLike } from 'api';

type Props = PropsWithChildren<{
  data: TPostDetail;
  disableClick?: boolean;
  onClickAvatar?: () => void;
  onLoad?: () => void;
  className?: string;
  onConfirmGift?: (data: TPostDetail) => void;
}>;

function PostItem({
  data: postDetail,
  disableClick = false,
  onClickAvatar,
  onLoad,
  className = '',
  onConfirmGift,
}: Props) {
  // Hooks
  const navigate = useNavigate();
  const { listPostPaid } = usePostStore();
  const { lockedGift, refetchPoints } = useUser();
  const { user } = useUserStore();

  // States
  const [openGift, setOpenGift] = useState<boolean>(false);
  const [pushedLikePost, setPushedLikePost] = useState<boolean>(false);

  // Memo, callbacks
  const data = useMemo(
    () => listPostPaid.find((post) => post.id === postDetail.id) || postDetail,
    [listPostPaid, postDetail]
  );

  // Effect
  useEffect(() => {
    setPushedLikePost(data.isLiked);
  }, [data.id, user, data.isLiked]);

  const navigateToDetail = useCallback(() => {
    if (disableClick) return;
    onClickAvatar && onClickAvatar();
    navigate(routes.DETAIL_FEMALE.replace(':id', data.user.id));
  }, [data.user.id, disableClick, navigate, onClickAvatar]);

  const { mutateAsync: increaseLike } = useMutation({
    mutationFn: async (body: TUpsertPostLike) => {
      const result = await createPostLike(body);
      if (!result) return;
      data.likeCount += 1;
      setPushedLikePost(true);
    },
  });

  const { mutateAsync: decreaseLike } = useMutation({
    mutationFn: async (body: TUpsertPostLike) => {
      const result = await deletePostLike(body);
      if (!result) return;
      data.likeCount -= 1;
      setPushedLikePost(false);
    },
  });

  const onLikeClick = useCallback(() => {
    if (!user) {
      navigate(routes.SIGNUP);
      return;
    }
    if (pushedLikePost) {
      decreaseLike({ postId: +data.id, maleLikerId: user.id });
    } else {
      increaseLike({ postId: +data.id, maleLikerId: user.id });
    }
  }, [data.id, decreaseLike, increaseLike, navigate, pushedLikePost, user]);

  return (
    <>
      <div className={className}>
        <div className={`flex gap-3`}>
          <div className={`flex-[1]`}>
            <Avatar
              onClick={navigateToDetail}
              circle
              url={data.user.avatar}
              canViewFull={
                data.user.applicationProvider === EUserProvider.MALE_APPLICATION
              }
            />
          </div>

          <div className={`flex-[7]`}>
            <Text className="w-fit">{data.user.nickname}</Text>
            <Text textColor="text-neutral" className="mt-1">
              {formatDate(data.approveDatetime, DATE_TIME_FORMAT)}
            </Text>
            <Text textColor="text-black" className="mt-2">
              {data.content}
            </Text>
            {!!data.media?.length && (
              <PostGallery
                id={data.id}
                data={data.media}
                onLoad={onLoad}
                isLockedPost={data?.isPremium && !data?.isPaidPremium}
                isPremiumPost={data?.isPremium}
                onPaidPost={() => {
                  setOpenGift(true);
                  refetchPoints();
                }}
              />
            )}
            <div
              onClick={onLikeClick}
              className="flex justify-end items-center mt-4 mr-4"
            >
              <div className='scale-[1.3]'>
                <Icon icon={pushedLikePost ? 'heartActive' : 'heartInActive'} />
              </div>
              <Text fontSize={14} textColor="text-black/50 ml-2 mb-1">
                {data.likeCount}
              </Text>
            </div>
          </div>
        </div>
      </div>
      <ModalGift
        open={openGift}
        onClose={() => setOpenGift(false)}
        onConfirm={() => {
          setOpenGift(false);
          onConfirmGift && onConfirmGift(data);
        }}
        gift={data?.gift}
        isLocked={lockedGift(data?.gift)}
      />
    </>
  );
}

export default memo(PostItem);
