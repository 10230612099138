import {
  AppInfoLink,
  Button,
  IconButton,
  Modal,
  PhoneInput,
  Text,
} from 'components';
import { TEXT_STRING, routes } from '../../constants';
import { useAuth, useToast } from 'hooks';
import React from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { TPhoneInput } from 'types';
import { validatePhone } from 'utils';
import { Country } from 'react-phone-number-input';
import Verify from 'components/Verify';
import { useChatStore, useUserStore } from 'store';
import useOrderStore from 'store/useOrderStore';

const countryCode = (process.env.REACT_APP_COUNTRY_CODE || 'JP') as Country;
const loginStr = TEXT_STRING.LOGIN;
const commonStr = TEXT_STRING.COMMON;

function Login() {
  // State
  const [phoneState, setPhoneState] = React.useState<TPhoneInput>({
    countryCode,
    number: '',
  });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [inVerify, setInVerify] = React.useState<boolean>(false);

  // Hooks
  const { sendOTP, verifyOTP, verifyPhone, login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { toastError } = useToast();

  // Store
  const { isAuthenticated } = useUserStore();
  const { redirectOrderStep } = useOrderStore();
  const { removeLeveragePending } = useChatStore();

  const from = React.useMemo(
    () => location.state?.from?.pathname || '/',
    [location]
  );

  const onLogin = React.useCallback(async () => {
    if (validatePhone(phoneState)) {
      if (phoneState.number) {
        try {
          setLoading(true);
          await verifyPhone(phoneState.number, true);
          await sendOTP(phoneState.number);
          setInVerify(true);
        } catch (error) {
          toastError(error as Error, { position: 'top-center' });
        } finally {
          setLoading(false);
        }
      }
    }
  }, [phoneState, verifyPhone, sendOTP, toastError]);

  const onCancel = React.useCallback(() => {
    removeLeveragePending();
    navigate(-1);
  }, [removeLeveragePending, navigate]);

  const onVerify = React.useCallback(
    async (otp: string) => {
      try {
        setLoading(true);
        const token = await verifyOTP(otp);
        if (token) await login(token);
        onCancel();
        redirectOrderStep();
      } catch (error) {
        toastError(error as Error, { position: 'top-center' });
      } finally {
        setLoading(false);
      }
    },
    [verifyOTP, login, onCancel, redirectOrderStep, toastError]
  );

  if (isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    // return <h1>Protected Route</h1>;
    return <Navigate to={from} state={{ from: location }} replace />;
  }

  return (
    <div className="absolute top-0 bg-black w-full h-full z-10">
      <Modal
        open={true}
        onClose={() => {}}
        className={`px-5 py-6 mx-5 bg-white w-full`}
        positionLayout="fixed"
      >
        {inVerify ? (
          <Verify
            phone={phoneState.number || ''}
            verifyOTP={onVerify}
            resendOTP={() => sendOTP(phoneState.number || '')}
            loading={loading}
          />
        ) : (
          <>
            <IconButton
              icon="close"
              transparent
              className="fixed top-0 right-0 z-50 m-4"
              onClick={onCancel}
            />
            <div className="bg-white">
              <div className="text-center mb-16px">
                <p className="" id="modal-title">
                  {loginStr.WARNING_LOGIN}
                </p>
              </div>
              <PhoneInput
                label={`電話番号`}
                name="phone"
                value={phoneState}
                onChange={setPhoneState}
                placeholder={`03 1234 5678`}
                className="mb-16px"
                error={!validatePhone(phoneState)}
              />

              <Text className="my-16px" bold fontSize={14}>
                {commonStr.REGISTER}{' '}
                <span
                  className="text-primary cursor-pointer"
                  onClick={() => {
                    navigate(routes.SIGNUP, {
                      replace: true,
                    });
                  }}
                >
                  {loginStr.HERE}
                </span>
              </Text>

              <AppInfoLink />
            </div>
            <div className="">
              <Button onClick={onLogin} block className="" disabled={loading}>
                {commonStr.LOGIN}
              </Button>
              <Button
                onClick={onCancel}
                block
                textColor="text-primary"
                bgColor="bg-white"
                className="py-0 mt-4"
                disabled={loading}
              >
                {commonStr.CANCEL}
              </Button>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
}

export default Login;
