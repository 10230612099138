type TActionCookie = {
  key: string;
  value?: string;
  expires?: string;
};

const getCookies = (key: string) => {
  const cookies = document.cookie
    .split('; ')
    .reduce((result: Record<string, string>, current) => {
      const entries = current.split('=');
      const key = entries[0];
      const value = entries[1];
      if (key && value) result[key] = value;
      return result;
    }, {});
  return cookies?.[key];
};

const setCookie = (data: TActionCookie[]) => {
  data.forEach(({ key, value, expires }) => {
    const defaultExpires = new Date(
      new Date().getTime() + 1000 * 60 * 60 * 24 * 365 * 20 // ~20 years
    );

    document.cookie = `${key}=${value}; expires=${
      expires || defaultExpires.toUTCString()
    }; path=/`;
  });
};

const deleteCookie = (keys: string[]) => {
  keys.forEach((key) => (document.cookie = `${key}=; Max-Age=0; path=/`));
};

export { getCookies, setCookie, deleteCookie };
