import React, { useMemo } from 'react';
import ModalSuccess from '..';
import Text from 'components/Text';
import { EPaymentMethod } from 'types';
import { TEXT_STRING } from '../../../constants';
import { getFormattedAmount } from 'utils';

const paymentStr = TEXT_STRING.PAYMENT;

const successContent = {
  [EPaymentMethod.VISA]: paymentStr.PAY_CARD_SUCCESS,
  [EPaymentMethod.CARD]: paymentStr.PAY_CARD_SUCCESS,
  [EPaymentMethod.CASH]: paymentStr.PAY_CASH_SUCCESS,
  [EPaymentMethod.POINT]: paymentStr.PAY_POINT_SUCCESS,
};

type Props = {
  open: boolean;
  paymentMethod: EPaymentMethod;
  onConfirm: () => void;
  amount: number;
  fee?: number;
  text?: string;
};
function ModalPaymentSuccess({
  open,
  paymentMethod,
  onConfirm,
  amount,
  fee,
  text,
}: Props) {
  const amountPaid = useMemo(() => {
    let amountPayment = amount;
    if (fee) {
      amountPayment = amount + amount * fee;
    }
    return getFormattedAmount(amountPayment);
  }, [amount, fee]);

  return (
    <ModalSuccess open={open} onConfirm={onConfirm}>
      <Text className="mt-6" center>
        {text || successContent[paymentMethod].replace('$amount', amountPaid)}
      </Text>
    </ModalSuccess>
  );
}
export default ModalPaymentSuccess;
