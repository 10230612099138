import { COOKIE_KEYS } from '../constants';
import { useCallback, useEffect } from 'react';
import { detectBrowserVersion, detectPWA, getCookies, setCookie } from 'utils';
import { useMutation } from '@tanstack/react-query';
import { accessApp } from 'api';
import { TAccessApp } from 'types';
import { useUserStore } from 'store';

const isApplyABTesting = !!+getCookies(COOKIE_KEYS.APPLY_AB_TESTING);
const isPWA = detectPWA();
const browserVersion = detectBrowserVersion();
const browser = browserVersion?.[0]?.toLocaleLowerCase();
const version = +browserVersion?.[1];
// Version available to save cookies when install app
const isValidVersion = browser === 'safari' && version >= 17.2;

function useABTesting() {
  const {
    isAuthenticated,
    clientId,
    isUserApplyABTesting,
    setIsUserApplyABTesting,
  } = useUserStore();

  const { mutateAsync: mutateAccessApp } = useMutation({
    mutationFn: (body: TAccessApp) => {
      return accessApp(body);
    },
    onSuccess(data) {
      setCookie([
        {
          key: COOKIE_KEYS.APPLY_AB_TESTING,
          value: `${+data?.isApply}`,
        },
      ]);
      if (data?.isApply) {
        setIsUserApplyABTesting(!isPWA);
      }
    },
  });

  const handleAccessApp = useCallback(async () => {
    if (isValidVersion && !isAuthenticated && !isPWA) {
      await mutateAccessApp({
        version: `${browserVersion}`,
        clientId: clientId || '',
      });
    }
  }, [clientId, isAuthenticated, mutateAccessApp]);

  useEffect(() => {
    if (isApplyABTesting) {
      setIsUserApplyABTesting(!isPWA && isValidVersion);
    }
  }, [isUserApplyABTesting, setIsUserApplyABTesting, clientId]);

  return { handleAccessApp, isUserApplyABTesting };
}

export default useABTesting;
