import axios from 'axios';
import { COOKIE_KEYS } from '../constants';
import {
  TChannel,
  TChannelDetail,
  TCreateChannel,
  TListResult,
  TResPin,
  TUnreadCount,
} from 'types';
import { getCookies } from 'utils';

const getChannelList = async (
  currentPage: number,
  pageSize: number,
  search?: string
): Promise<TListResult<TChannel>> => {
  const res = await axios
    .get('messages/channel/male', {
      params: {
        currentPage: currentPage,
        pageSize: pageSize,
        search,
      },
    })
    .then(function (response) {
      return response.data;
    });

  return res;
};

const getChannelDetail = async (id: string): Promise<TChannelDetail> => {
  const res = await axios
    .get(`messages/channel/${id}`)
    .then(function (response) {
      return response.data;
    });

  return res;
};

const updateSeenTime = async (id: string): Promise<boolean> => {
  const res = await axios
    .patch(`messages/channel/${id}/seen`)
    .then(function (response) {
      return response.data;
    });

  return res;
};

const updateChannelActive = async (id: string): Promise<boolean> => {
  const res = await Promise.all([
    axios.patch(`messages/channel/${id}/sent`).then(function (response) {
      return response.data;
    }),
  ]).then(() => true);

  return res;
};

const createChannel = async (body: TCreateChannel): Promise<TChannel> => {
  return await axios
    .post(`messages/channel/male`, body)
    .then(function (response) {
      return response.data;
    });
};

const getUnreadMessagesCount = async (): Promise<{
  channels: TUnreadCount;
}> => {
  const clientId = getCookies(COOKIE_KEYS.CLIENT_ID);
  return await axios
    .get(`messages/channel/male/unread-count`, {
      params: {
        clientId,
      },
    })
    .then(function (response) {
      return response.data;
    });
};

// Chat without logging in
const getClientChannelList = async (
  currentPage: number,
  pageSize: number,
  clientId: string,
  search?: string
): Promise<TListResult<TChannel>> => {
  const res = await axios
    .get('messages/channel/male/client', {
      params: {
        currentPage: currentPage,
        pageSize: pageSize,
        clientId,
        search,
      },
    })
    .then(function (response) {
      return response.data;
    });

  return res;
};

const getClientChannelDetail = async (id: string): Promise<TChannelDetail> => {
  const clientId = getCookies(COOKIE_KEYS.CLIENT_ID);
  const res = await axios
    .get(`messages/channel/client/${id}`, { params: { clientId } })
    .then(function (response) {
      return response.data;
    });

  return res;
};

const createClientChannel = async (body: TCreateChannel): Promise<TChannel> => {
  const clientId = getCookies(COOKIE_KEYS.CLIENT_ID);
  if (!clientId) throw new Error('');

  return await axios
    .post(`messages/channel/male/client`, { ...body, clientId })
    .then(function (response) {
      return response.data;
    });
};

const updateClientSeenTime = async (
  channelId: string,
  clientId?: string
): Promise<boolean> => {
  if (!clientId) return false;
  const res = await axios
    .patch(`messages/channel/client/${channelId}/seen`, { clientId })
    .then(function (response) {
      return response.data;
    });

  return res;
};

const updateClientChannelActive = async (
  channelId: string,
  clientId: string
): Promise<boolean> => {
  if (!clientId) return false;
  const res = await Promise.all([
    axios
      .patch(`messages/channel/client/${channelId}/sent`, { clientId })
      .then(function (response) {
        return response.data;
      }),
  ]).then(() => true);

  return res;
};

const createManagementClientChannel = async (
  clientId: string,
  isAuto: boolean
): Promise<TChannel | undefined> => {
  if (!clientId) return;
  const res = await axios
    .post(`messages/channel/male/client/management`, { clientId, isAuto })
    .then(function (response) {
      return response.data;
    });

  return res;
};

// Pin
const pinChannel = async (channelId: string): Promise<TResPin> => {
  const res = await axios
    .patch('messages/channel/pin', { channelId })
    .then(function (response) {
      return response.data;
    });

  return res;
};

const unpinChannel = async (channelId: string): Promise<TResPin> => {
  const res = await axios
    .patch('messages/channel/un-pin', { channelId })
    .then(function (response) {
      return response.data;
    });

  return res;
};

export {
  getChannelList,
  getChannelDetail,
  updateChannelActive,
  createChannel,
  updateSeenTime,
  getClientChannelList,
  createClientChannel,
  getUnreadMessagesCount,
  getClientChannelDetail,
  updateClientSeenTime,
  updateClientChannelActive,
  createManagementClientChannel,
  pinChannel,
  unpinChannel,
};
