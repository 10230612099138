import { Button, Modal, Text } from 'components';
import React from 'react';
import { ReactComponent as Warning } from 'assets/images/18plus.svg';
import { TEXT_STRING, routes, storageKeys } from '../../constants';
import { useNavigate } from 'react-router-dom';

const modalStr = TEXT_STRING.MODAL;
const commonStr = TEXT_STRING.COMMON;

const castUrl = process.env.REACT_APP_CAST_URL || '';
const staffUrl = process.env.REACT_APP_STAFF_URL || '';
const tkUrl = process.env.REACT_APP_TK_URL || '';
const fcRecruitUrl = process.env.REACT_APP_FRANCHISE_RECRUITMENT_URL || '';

function WarningModal() {
  const [open, setOpen] = React.useState<boolean>(
    localStorage.getItem(storageKeys.IS_CONFIRM)
      ? !localStorage.getItem(storageKeys.IS_CONFIRM)
      : true
  );

  const navigate = useNavigate();

  const onReject = React.useCallback(() => {
    window.close();
    window.open('about:blank', '_self');
  }, []);

  const onConfirm = React.useCallback(() => {
    setOpen(false);
    localStorage.setItem(storageKeys.IS_CONFIRM, 'true');
  }, []);

  return (
    <Modal
      open={open}
      className="max-w-3xl p-16px text-sm tracking-wider text-center mx-5 bg-white w-full"
      onClose={() => {}}
    >
      <div className="pt-3 flex flex-col gap-5">
        <Warning className="m-auto" />
        <div>
          <Text className="mt-6 text-[1.4em] mb-4" center bold>
            {modalStr.WARNING_18}
          </Text>
          <Text center className="mb-2">
            {modalStr.WARNING_MESSAGE}
          </Text>
          <Text
            className="underline underline-offset-4"
            textColor="text-blue-400"
            center
            onClick={() => {
              navigate(routes.TERMS_OF_SERVICE);
              onConfirm();
            }}
          >
            {TEXT_STRING.APP_INFO.TERMS_OF_SERVICE}
          </Text>
        </div>
        <div className="flex flex-col gap-5 mt-2">
          <Button className="w-full py-6" onClick={onConfirm}>
            {modalStr.CUSTOMER_HERE}
          </Button>
          <div className="flex gap-5">
            <Button
              className="py-6 font-normal rounded-[8px]"
              bgColor="bg-primary-50 border border-solid border-primary"
              onClick={() => window.open(`${tkUrl}${staffUrl}`, '_blank')}
              block
              textColor="text-primary"
            >
              {modalStr.OFFICE_WORKER}
            </Button>
            <Button
              className="py-6 font-normal rounded-[8px]"
              bgColor="bg-primary-50 border border-solid border-primary"
              onClick={() => window.open(`${tkUrl}${castUrl}`, '_blank')}
              block
              textColor="text-primary"
            >
              {modalStr.GIRL_JOB}
            </Button>
          </div>
          <Button
            className="w-full py-6"
            bgColor="bg-primary-50 border border-solid border-primary"
            onClick={() => window.open(fcRecruitUrl, '_blank')}
            textColor="text-primary"
          >
            {modalStr.FRANCHISE_RECRUITMENT}
          </Button>
        </div>
        <Text className="" center onClick={onReject}>
          {commonStr.QUIT}
        </Text>
      </div>
    </Modal>
  );
}

export default WarningModal;
