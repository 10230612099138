import { Outlet, useLocation } from 'react-router-dom';
import { routes, TEXT_STRING } from '../../constants';
import { Header, Text } from 'components';
import { useMemo } from 'react';

const pageTitle = {
  [routes.TERMS_OF_SERVICE]: {
    header: TEXT_STRING.APP_INFO.TERMS_OF_SERVICE,
    title: TEXT_STRING.APP_INFO.TERMS_OF_SERVICE,
  },
  [routes.PRIVACY_POLICY]: {
    header: TEXT_STRING.APP_INFO.PRIVACY_POLICY,
    title: TEXT_STRING.APP_INFO.PRIVACY_POLICY,
  },
  [routes.SPECIAL_COMMERCIAL_LAW]: {
    header: TEXT_STRING.MY_PAGE.SPECIAL_COMMERCIAL_LAW,
    title: TEXT_STRING.APP_INFO.SPECIAL_COMMERCIAL_LAW,
  },
};

function AppInfo() {
  const location = useLocation();
  const { header, title } = useMemo(() => {
    return pageTitle[location.pathname];
  }, [location]);

  return (
    <div className="h-full overflow-y-auto">
      <Header title={header} />
      <div className="p-5 pb-10">
        <Text center bold fontSize={14}>
          {title}
        </Text>
        <Outlet />
      </div>
    </div>
  );
}

export default AppInfo;
