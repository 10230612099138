import {
  Button,
  Chip,
  Icon,
  Modal,
  MultipleSelect,
  Text,
  TextField,
} from 'components';
import { LIMIT_VALUE, TEXT_STRING } from '../../constants';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  EFilterFemale,
  ESearchTab,
  TSelectOption,
  TTextFieldChangeEvent,
} from 'types';
import { useAppStore } from 'store';
import { initialFilterOptions } from 'helpers';

const str = TEXT_STRING.SEARCH_PAGE;
const commonStr = TEXT_STRING.COMMON;

enum ETypeForm {
  SEARCH_INPUT = 'SEARCH_INPUT',
  MULTIPLE_SELECT = 'MULTIPLE_SELECT',
  TAGS = 'TAGS',
}

type TFields = {
  name: EFilterFemale;
  label?: string;
  type: string;
  list?: TChipList[];
  placeholder?: string;
  options?: TSelectOption[];
};

type TChipList = { id: number; label: string };

type ModalSearchProps = {
  currentTab: ESearchTab;
  onSearch: (filter: Record<string, number[]>, keywords: string) => void;
  onClose: () => void;
  open?: boolean;
  filtered?: Record<string, number[]>;
  keywords?: string;
};

const textOptions: Record<string, string> = {
  styles: str.STYLES,
  lineage: str.LINEAGE,
  enjoy_method: str.ENJOY_METHOD,
  age_range: TEXT_STRING.CAST_PAGE.AGE_NUMBER,
  game_play: str.GAME_PLAY,
};

function ModalFilter({
  currentTab,
  open = false,
  onClose,
  onSearch,
  filtered,
  keywords,
}: ModalSearchProps) {
  // Hooks
  const { filterOptions, franchiseAreas } = useAppStore();

  // States
  const [listFiltered, setListFiltered] = useState<Record<string, number[]>>(
    filtered || initialFilterOptions
  );
  const [search, setSearch] = useState<string>(keywords || '');

  // Memo, callbacks
  const fields: TFields[] = useMemo(() => {
    const { tags, ranks, starRanks } = filterOptions || {};

    return [
      {
        name: EFilterFemale.ACTIVITY_AREAS,
        type: ETypeForm.MULTIPLE_SELECT,
        label:
          currentTab === ESearchTab.SCHEDULES
            ? TEXT_STRING.CAST_PAGE.WORK_AREA
            : TEXT_STRING.CAST_PAGE.AFFILIATION_AREA,
        options: franchiseAreas
          ?.filter((area) => !area?.isOtherArea)
          ?.map((item) => ({
            name: item.name,
            value: `${item.id}`,
          })),
      },
      {
        name: EFilterFemale.STAR,
        type: ETypeForm.MULTIPLE_SELECT,
        label: str.RATING,
        options: starRanks?.map((item) => ({
          name: `${item.star}`,
          value: `${item.id}`,
        })),
      },
      {
        name: EFilterFemale.RANKS,
        type: ETypeForm.TAGS,
        label: commonStr.RANK,
        list: ranks?.map((rank) => ({ id: rank.id, label: rank.rank })),
      },
      ...Object.keys(tags || {}).map((key) => {
        return {
          name: EFilterFemale.OPTIONS,
          type: ETypeForm.TAGS,
          label: textOptions[key] || key,
          list: tags?.[key],
        };
      }),
      {
        name: EFilterFemale.KEYWORDS,
        type: ETypeForm.SEARCH_INPUT,
        label: str.SEARCH_BY_KEYWORD,
        placeholder: str.ENTER_KEYWORD,
      },
    ];
  }, [currentTab, filterOptions, franchiseAreas]);

  const handleFilter = useCallback(
    (value: number, key: EFilterFemale) => {
      setListFiltered((prev) => ({
        ...prev,
        [key]: listFiltered[key]?.includes(value)
          ? prev[key].filter((i) => i !== value)
          : [...prev[key], value],
      }));
    },
    [listFiltered]
  );

  const handleSearch = useCallback((e: TTextFieldChangeEvent) => {
    const value = e.target.value;
    setSearch((prev) =>
      value.length > LIMIT_VALUE.MAX_LENGTH_SEARCH_KEYWORD ? prev : value
    );
  }, []);

  const renderFields = useCallback(
    (fields: TFields[]) => {
      return fields.map((field, index) => {
        return (
          <div key={index}>
            <Text className="mb-2" bold textColor="text-neutral-550">
              {field.label}
            </Text>
            {(() => {
              switch (field.type) {
                case ETypeForm.SEARCH_INPUT:
                  return (
                    <>
                      <TextField
                        name="search"
                        onChange={handleSearch}
                        value={search}
                        placeholder={field.placeholder}
                        prefix={<Icon icon="searchInput" />}
                        className="px-2.5 py-1 ring-inset my-2"
                      />
                      <Text textColor="text-neutral" fontWeight="font-medium">
                        {str.SEARCH_NOTE}
                      </Text>
                    </>
                  );
                case ETypeForm.MULTIPLE_SELECT:
                  const options = field?.options || [];
                  return (
                    <MultipleSelect
                      name={field.name}
                      options={options}
                      values={options?.filter((option) =>
                        listFiltered?.[field.name].includes(+option.value)
                      )}
                      onChange={(values: TSelectOption[]) => {
                        setListFiltered((prev) => ({
                          ...prev,
                          [field.name]: values.map((item) => +item.value),
                        }));
                      }}
                      rounded="rounded-lg"
                      isSelectRating={field.name === EFilterFemale.STAR}
                    />
                  );
                case ETypeForm.TAGS:
                  return (
                    <div className="flex flex-wrap gap-3">
                      {field?.list?.map((item) => {
                        const active = listFiltered[field.name].includes(
                          item.id
                        );
                        return (
                          <Chip
                            key={item.id}
                            ringColor={
                              active ? 'ring-primary' : 'ring-neutral-200'
                            }
                            textColor={
                              active ? 'text-primary' : 'text-neutral-300'
                            }
                            className="bg-white cursor-pointer"
                            onClick={() => handleFilter(item.id, field.name)}
                          >
                            {item.label}
                          </Chip>
                        );
                      })}
                    </div>
                  );
                default:
                  return <></>;
              }
            })()}
          </div>
        );
      });
    },
    [handleFilter, handleSearch, listFiltered, search]
  );

  // Effect
  useEffect(() => {
    if (filtered) {
      setListFiltered(filtered);
    }
  }, [filtered]);

  useEffect(() => {
    if (keywords) {
      setSearch(keywords);
    }
  }, [keywords]);

  return (
    <Modal open={open} className="p-16px bg-white" onClose={() => {}}>
      <div className="flex flex-col justify-between gap-5 max-h-[65dvh] h-[70vh]">
        <div className="relative py-2">
          <div
            onClick={onClose}
            className="absolute left-0 top-1.5 cursor-pointer"
          >
            <Icon icon={'close'} />
          </div>
          <Text center bold>
            {str.REFINE}
          </Text>
          <div className="absolute right-0 top-0 cursor-pointer">
            <Button
              className="py-2"
              onClick={() => {
                setListFiltered(initialFilterOptions);
                setSearch('');
              }}
            >
              {TEXT_STRING.COMMON.RESET}
            </Button>
          </div>
        </div>

        <div className="flex flex-col gap-5 overflow-y-auto">
          {renderFields(fields)}
        </div>
        <Button
          className="w-full py-5"
          onClick={() => onSearch(listFiltered, search)}
          bold
        >
          {str.SEARCH}
        </Button>
      </div>
    </Modal>
  );
}

export default ModalFilter;
