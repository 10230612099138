import { Link } from 'react-router-dom';
import { routes, TEXT_STRING } from '../../constants';

function AppInfoLink() {
  return (
    <div className="flex flex-col gap-16px mb-16px">
      <Link
        className={`text-blue-400 underline underline-offset-4`}
        to={routes.SPECIAL_COMMERCIAL_LAW}
        key={routes.SPECIAL_COMMERCIAL_LAW}
      >
        {TEXT_STRING.MY_PAGE.SPECIAL_COMMERCIAL_LAW}
      </Link>

      <Link
        className={`text-blue-400 underline underline-offset-4`}
        to={routes.PRIVACY_POLICY}
        key={routes.PRIVACY_POLICY}
      >
        {TEXT_STRING.MY_PAGE.PRIVACY_POLICY}
      </Link>
    </div>
  );
}

export default AppInfoLink;
