import { Text } from 'components';
import TEXT_APP_INFO from 'constants/appInfo';

function TermsOfService() {
  return (
    <>
      {TEXT_APP_INFO.TERMS_OF_SERVICE_CONTENT.map((item, index) => {
        return (
          <div key={index}>
            <Text bold className="mt-4 leading-7">
              {item.text}
            </Text>
            <Text className="pl-6">{item.content}</Text>
            <ul className="list-decimal pl-6">
              {item.children?.map((c, ind) => {
                return (
                  <li key={ind}>
                    <Text className="leading-7">{c.text}</Text>
                    {c.children && (
                      <ul>
                        {c.children.map((text, i) => {
                          return (
                            <div key={i} className="flex gap-2">
                              <span>({i + 1})</span>
                              <li>
                                <Text className="leading-7">{text}</Text>
                              </li>
                            </div>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </>
  );
}

export default TermsOfService;
