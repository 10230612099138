import { ESystemMobile } from 'types';

const userAgent = navigator.userAgent;

const detectMobile = () => {
  return /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(
    userAgent
  );
};

const detectSystemMobile = () => {
  const isIOS = /iPad|iPhone|iPod|Mac/.test(userAgent);
  const isAndroid = /Android/.test(userAgent);

  if (isIOS) {
    return ESystemMobile.IOS;
  } else if (isAndroid) {
    return ESystemMobile.ANDROID;
  } else {
    return ESystemMobile.OTHER;
  }
};

const detectPWA = () => {
  if (window.matchMedia('(display-mode: standalone)').matches) {
    return true;
  } else {
    return false;
  }
};

// https://stackoverflow.com/questions/5916900/how-can-you-detect-the-version-of-a-browser
const detectBrowserVersion = () => {
  const ua = navigator?.userAgent;
  let tem;
  let M =
    ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) ||
    [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return 'IE ' + (tem[1] || '');
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
  }
  M = M[2] ? [M[1], M[2]] : [navigator?.appName, navigator?.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);

  return M;
};

export { detectMobile, detectSystemMobile, detectPWA, detectBrowserVersion };
