import axios from 'axios';
import {
  TFollowed,
  TListFemale,
  TSendActive,
  TUpdateEmail,
  TUser,
  TListFemaleMet,
  TSendReview,
  TResFemaleReview,
  TPostResponse,
  TBodyProfile,
  TAccessApp,
  TResAccessApp,
  TLpTracking,
} from 'types';

const followFemale = async (id: number): Promise<TFollowed> => {
  const res = await axios.get(`male/following/${id}`).then(function (response) {
    return response.data;
  });

  return res;
};

const unFollowFemale = async (id: number): Promise<TFollowed> => {
  const res = await axios
    .get(`male/un-following/${id}`)
    .then(function (response) {
      return response.data;
    });

  return res;
};

const getFollowingList = async (
  currentPage: number,
  pageSize: number
): Promise<TListFemale> => {
  const res = await axios
    .get('male/following-list', {
      params: {
        currentPage: currentPage,
        pageSize: pageSize,
      },
    })
    .then(function (response) {
      return response.data;
    });

  return res;
};

const getFemalesMet = async (
  currentPage: number,
  pageSize: number
): Promise<TListFemaleMet> => {
  const res = await axios
    .get('male/female-met', {
      params: { currentPage, pageSize },
    })
    .then(function (response) {
      return response.data;
    });

  return res;
};

const getCurrentUser = async (): Promise<TUser> => {
  return await axios.get(`male/current-user`).then(function (response) {
    return response.data;
  });
};

const sendActive = async (body: TSendActive) => {
  return await axios.patch(`male/active`, body).then(function (response) {
    return response.data;
  });
};

const updateEmail = async (body: TUpdateEmail) => {
  return await axios.patch('male/email', body).then((response) => {
    return response.data;
  });
};

const getPendingReviewList = async (): Promise<TResFemaleReview[]> => {
  const res = await axios.get('male/reviews/pending').then(function (response) {
    return response.data;
  });

  return res;
};

const sendReview = async (body: TSendReview) => {
  return await axios.patch('male/reviews', body).then((response) => {
    return response.data;
  });
};

const getDetailPosts = async (params: {
  maleId: number;
  currentPage: number;
  pageSize: number;
}): Promise<TPostResponse> => {
  const res = await axios.get('post', { params }).then(function (response) {
    return response.data;
  });

  return res;
};

const editProfile = async (body: TBodyProfile): Promise<{ userId: string }> => {
  return await axios.patch('male/profile', body).then((response) => {
    return response.data;
  });
};

const accessApp = async (body: TAccessApp): Promise<TResAccessApp> => {
  return await axios.post('male/access', body).then((response) => {
    return response.data;
  });
};

const upsertLpTracking = async (body: TLpTracking): Promise<boolean> => {
  return await axios.post('male/lp-tracking', body).then((response) => {
    return response.data;
  });
};

export {
  followFemale,
  unFollowFemale,
  getFollowingList,
  getCurrentUser,
  sendActive,
  updateEmail,
  getFemalesMet,
  getPendingReviewList,
  sendReview,
  editProfile,
  getDetailPosts,
  accessApp,
  upsertLpTracking,
};
