import { routes } from './routes';
import TEXT_STRING from './string';
import RankRoyal from '../assets/images/rankBg/royal.svg';
import RankVip from '../assets/images/rankBg/vip.svg';
import RankPremium from '../assets/images/rankBg/premium.svg';
import RankDefault from '../assets/images/rankBg/default.svg';
import IphoneStep1 from '../assets/images/tutorial/iphoneStep1.png';
import IphoneStep2 from '../assets/images/tutorial/iphoneStep2.png';
import AndroidStep1 from '../assets/images/tutorial/androidStep1.png';
import AndroidStep2 from '../assets/images/tutorial/androidStep2.png';
import AndroidStep3 from '../assets/images/tutorial/androidStep3.png';
import firebaseConfig from './firebaseConfig';

const storageKeys = {
  IS_CONFIRM_TUTORIAL: 'is_confirm_tutorial',
  IS_CONFIRM: 'is_confirm',
  USER_ID: 'user_id',
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
  CLIENT_ID: 'client_id',
  FILTER_FEMALE: 'filter_female',
  REQUIRE_SIGNUP: 'require_signup',
  POINT_TAB: 'point_tab',
  GIFT_SUGGESTIONS_DISPLAYED: 'gift_suggestions_displayed',
};

const SESSION_KEYS = {
  HOME_TAB: 'home_tab',
  SEARCH_TAB: 'search_tab',
  DISCOUNT_TAB: 'discount_tab',
  INVITE_ID: 'invite_id',
};

const COOKIE_KEYS = {
  APPLY_AB_TESTING: 'is_apply_ab_testing',
  INVITE_ID: 'invite_id',
  CLIENT_ID: 'client_id',
};

const queryKeys = {
  FEMALE_LIST: 'FemaleList',
  FOLLOWING_LIST: 'FollowingList',
  DETAIL_FEMALE: 'DetailFemale',
  FEMALE_POST: 'female_post',
  BODY_OPTIONS: 'BodyOptions',
  GAMES: 'Games',
  RECOMMEND_FEMALE: 'RecommendFemale',
  RECOMMEND_ORDER: 'RecommendOrder',
  PROJECT_INFO: 'ProjectInfo',
  PROJECT_FEMALES: 'project_females',
  PROJECT_PRICE: 'project_price',
  PROJECT_LEVERAGE: 'project_leverage',
  TOTAL_LEVERAGE_APPROVED: 'total_leverage_approved',
  CURRENT_USER: 'CurrentUser',
  POST_RECOMMEND: 'post_recommend',
  POST_FOLLOWED: 'post_followed',
  POST_PREMIUM: 'post_premium',
  FEMALE_RANK: 'female_rank',
  FILTER_OPTIONS: 'filter_options',
  CHANNEL_LIST: 'channel_list',
  CLIENT_CHANNEL_LIST: 'client_channel_list',
  MESSAGE_LIST: 'message_list',
  CHANNEL_DETAIL: 'channel_detail',
  CLIENT_CHANNEL_DETAIL: 'client_channel_detail',
  SELECTED_GAMES: 'selected_games',
  FCM_TOKEN: 'fcm_token',
  CARD_PAYMENT: 'card_payment',
  ORDER_HISTORY: 'order_history',
  PAYMENT: 'payment',
  ACTIVITY_AREA: 'activity_area',
  VIDEO_GAME: 'video_game',
  FEMALES_MET: 'females_met',
  STAR_RANK: 'star_rank',
  LIST_REVIEW: 'list_review',
  UNREAD_COUNT: 'unread_count',
  ADMIN_LIST: 'admin_list',
  MALE_POST: 'male_post',
  GIFT_DATA: 'gift_data',
  GIFT_LIST: 'gift_list',
  GIFT_PENDING_PAYMENT: 'gift_pending_payment',
  GIFT_PREDICT: 'gift_predict',
  PROHIBITED_WORDS: 'prohibited_words',
  POINTS: 'points',
  LIST_POINTS: 'list_points',
  BANK_TRANSFER_DETAIL: 'bank_transfer_detail',
  PENDING_REQUEST_POINT: 'pending_request_point',
  POINT_USAGE_HISTORY: 'point_usage_history',
  BUY_POINTS_HISTORY: 'buy_points_history',
  POINTS_PENDING: 'points_pending',
  FIND_USER: 'find_user',
  FEMALE_LIST_DISCOUNT: 'female_list_discount',
  LIST_PENDING_PAYMENT: 'list_pending_payment',
};

const MESSAGE_KEY = {
  GIRL_MEETING: 'girl_meeting',
  GIRL_CANCEL_MEETING: 'girl_cancel_meeting',
  ADMIN_RELEASE_PAYMENT: 'admin_release_payment',
};

const navBars = [
  { label: TEXT_STRING.NAVIGATION.SEARCH, icon: 'search', path: routes.SEARCH },
  { label: TEXT_STRING.NAVIGATION.HOME, icon: 'home', path: routes.HOME },
  {
    label: TEXT_STRING.NAVIGATION.CALL,
    img: '/images/logo192.png',
    path: routes.CALL,
  },
  {
    label: TEXT_STRING.NAVIGATION.MESSAGE,
    icon: 'message',
    path: routes.MESSAGE,
  },
  { label: TEXT_STRING.COMMON.MY_PAGE, icon: 'user', path: routes.MY_PAGE },
];

const RANK_STYLES: Record<number, { color: `text-${string}`; bg: string }> = {
  0: { color: 'text-black', bg: RankDefault },
  1: { color: 'text-black', bg: RankPremium },
  2: { color: 'text-black', bg: RankVip },
  3: { color: 'text-white', bg: RankRoyal },
};

const TIMEZONE = process.env.REACT_APP_TIMEZONE || 'Asia/Tokyo';
const PAGE_SIZE = 15;
const MAX_ORDER_GIRL = 20;
const PAGE_SIZE_POST = 30;
const DAY_IN_WEEK = 7;
const TIME_VIEW_FORMAT = `HH${TEXT_STRING.COMMON.HOUR}mm`;
const DATE_TIME_VIEW_FORMAT = `LL ${TIME_VIEW_FORMAT}`;
const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';
const SLASH_DATE_TIME_FORMAT = 'YYYY/MM/DD - HH:mm';
const DATE_FORMAT = 'YYYY/MM/DD';
const TIME_FORMAT = 'HH:mm';
const START_DAY_WORK = '11:00';
const LIMIT_FILE = 4;
const VIDEO_ACCEPTED = ['video/mp4', 'video/mov', 'video/quicktime'];
const IMAGE_ACCEPTED = [
  'image/png',
  'image/jpeg',
  'image/apng',
  'image/avif',
  'image/gif',
  'image/svg+xml',
  'image/webp',
];
const MESSAGE_SIZE = 25;
const DEBOUNCE_SEND_MESSAGE_ACTIVE = 1500;
const REFETCH_UNREAD_COUNT_TIMEOUT = 2000;
const DEBOUNCE_CLICK = 500;
const DEBOUNCE_TEXT = 500;
const ONE_MB = 1024 * 1000;
const MAX_FILE_SIZE_PUBNUB = ONE_MB * 5; // 5MB
const MAX_FILE_SIZE = ONE_MB * 10; // 10MB
const INTERVAL_SEND_ACTIVE = 60 * 1000;
const AUTO_PRICE = 20000;
const TIME_SHOW_REVIEW = 30 * 1000; // 30s
const MIN_NUMBER_SEARCH = 2;
const MAX_STAR = 5;
const LIMIT_VALUE = {
  MAX_REQUEST_IMAGE: 8,
  MAX_AGE: 999,
  MIN_AGE: 1,
  MAX_NUMBER: 1999999999,
  MIN_NUMBER: 0,
  MIN_TOUR_PRICE: 240000,
  MAX_TOUR_PRICE: 480000,
  MIN_AMOUNT_CARD: 100,
  // Minimum price to get star (one man JPY)
  MIN_PRICE_EMPTY_STAR: 0,
  MIN_PRICE_ONE_STAR: 5,
  MIN_PRICE_TWO_STAR: 20,
  MIN_PRICE_THREE_STAR: 50,
  MIN_PRICE_FOUR_STAR: 100,
  MIN_PRICE_FIVE_STAR: 200,
  MAX_LENGTH_SEARCH_KEYWORD: 10,
};
const ONE_MAN_JPY = 10000;
const EXCEED_FRANCHISE_GAME = {
  SINGLE: 3,
  GROUP: 5,
};

const SIGNAL_EVENT = {
  TYPING_ON: 'TYPING_ON',
  TYPING_OFF: 'TYPING_OFF',
  PROJECT_LEVERAGE: 'PROJECT_LEVERAGE',
  REVIEW_GIRL: 'REVIEW_GIRL',
  DISBAND_MEMBER: 'DISBAND_MEMBER',
  DELETE_MESSAGE: 'DELETE_MESSAGE',
  REVIEWED_MEMBER: 'REVIEWED_MEMBER',
};

const ERROR_CODE = {
  NOT_FOUND: 404,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  INTERNAL_SERVER_ERROR: 500,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
  CONFLICT: 409,
  UNPROCESSABLE_ENTITY: 422,
  TOO_MANY_REQUESTS: 429,
  NOT_IMPLEMENTED: 501,
  NOT_ACCEPTABLE: 406,
  REQUEST_TIMEOUT: 408,
  LENGTH_REQUIRED: 411,
  PRECONDITION_FAILED: 412,
  PAYLOAD_TOO_LARGE: 413,
  UNSUPPORTED_MEDIA_TYPE: 415,
  REQUESTED_RANGE_NOT_SATISFIABLE: 416,
  FAILED_DEPENDENCY: 424,
  LOCKED: 423,
  METHOD_NOT_ALLOWED: 405,
  PRECONDITION_REQUIRED: 428,
  URI_TOO_LONG: 414,
  NETWORK_AUTHENTICATION_REQUIRED: 511,
};

const STR = TEXT_STRING.TUTORIAL;

const tutorialModal = {
  iphone: {
    title: STR.ADD_TO_HOME,
    steps: [
      { img: IphoneStep1, text: STR.IPHONE_TUTORIAL_STEP_1 },
      {
        img: IphoneStep2,
        text: STR.IPHONE_TUTORIAL_STEP_2,
        reverse: true,
      },
      { img: '', text: STR.IPHONE_TUTORIAL_STEP_3 },
      { img: '', text: STR.TUTORIAL_STEP_4 },
    ],
  },
  android: {
    title: STR.ADD_TO_HOME,
    steps: [
      { img: AndroidStep1, text: STR.ANDROID_TUTORIAL_STEP_1 },
      {
        img: AndroidStep2,
        text: STR.ANDROID_TUTORIAL_STEP_2,
        reverse: true,
      },
      { img: AndroidStep3, text: STR.ANDROID_TUTORIAL_STEP_3 },
      { img: '', text: STR.TUTORIAL_STEP_4 },
    ],
  },
};

export {
  routes,
  storageKeys,
  queryKeys,
  navBars,
  TIMEZONE,
  PAGE_SIZE,
  TEXT_STRING,
  DATE_TIME_VIEW_FORMAT,
  DATE_TIME_FORMAT,
  DATE_FORMAT,
  TIME_VIEW_FORMAT,
  LIMIT_FILE,
  VIDEO_ACCEPTED,
  IMAGE_ACCEPTED,
  SESSION_KEYS,
  MAX_ORDER_GIRL,
  RANK_STYLES,
  MESSAGE_SIZE,
  SIGNAL_EVENT,
  ERROR_CODE,
  DEBOUNCE_SEND_MESSAGE_ACTIVE,
  REFETCH_UNREAD_COUNT_TIMEOUT,
  MAX_FILE_SIZE,
  tutorialModal,
  INTERVAL_SEND_ACTIVE,
  firebaseConfig,
  DEBOUNCE_CLICK,
  AUTO_PRICE,
  DEBOUNCE_TEXT,
  MIN_NUMBER_SEARCH,
  DAY_IN_WEEK,
  MAX_STAR,
  TIME_SHOW_REVIEW,
  MESSAGE_KEY,
  LIMIT_VALUE,
  ONE_MAN_JPY,
  PAGE_SIZE_POST,
  MAX_FILE_SIZE_PUBNUB,
  EXCEED_FRANCHISE_GAME,
  SLASH_DATE_TIME_FORMAT,
  ONE_MB,
  TIME_FORMAT,
  START_DAY_WORK,
  COOKIE_KEYS,
};
