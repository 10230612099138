import React, { useEffect } from 'react';
import './App.css';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Layout from 'layout';
import { ProtectedRoute, routers } from 'routers';
import { ERROR_CODE, routes } from './constants';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { detectMobile, getCookies } from 'utils';
import { WarningModal, TutorialModal } from 'components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga4';
import OneSignal from 'react-onesignal';
import { useUserStore } from 'store';
import { useAcsKeep } from 'hooks';
import { LIMITED_ROUTES } from 'constants/routes';
import { AxiosError } from 'axios';
import { upsertLpTracking } from 'api';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failedCount, err) => {
        const continueRetry = failedCount < 2;
        if (err instanceof AxiosError) {
          return err?.status !== ERROR_CODE.FORBIDDEN && continueRetry;
        }
        return continueRetry;
      },
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    },
  },
});

// Setup api payment
const PAYMENT_API_TOKEN = process.env.REACT_APP_PAYMENT_API_TOKEN;
const TRACKING_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
// Setup OneSignal
const APP_ID = process.env.REACT_APP_ONESIGNAL_APP_ID;

window.TokenPayment.setup(PAYMENT_API_TOKEN, () => {});

function App() {
  const isMobile = detectMobile();
  const navigate = useNavigate();
  const { setSubscriptionId, user } = useUserStore();

  useAcsKeep();

  useEffect(() => {
    if (TRACKING_ID) {
      ReactGA.initialize(TRACKING_ID);
      // Send pageview with a custom path
      ReactGA.send({
        hitType: 'pageview',
        page: '/',
        title: 'TECO APPLICATION',
      });
    }
  }, []);

  useEffect(() => {
    (async () => {
      const lpTmpMaleId = getCookies('tmp_user_id');
      if (!lpTmpMaleId) return;
      const lpLastVisitDate = new Date(getCookies('lp_last_visit_date'));
      const lpVisitCount = +getCookies('lp_visit_count');
      const lpReferrerUrl = getCookies('lp_referrer_url') || '';

      await upsertLpTracking({
        lpTmpMaleId,
        maleId: user?.id,
        lpVisitCount,
        lpLastVisitDate,
        lpReferrerUrl,
      });
    })();
  }, [user]);

  useEffect(() => {
    if (APP_ID) {
      OneSignal.init({
        appId: APP_ID,
        notificationClickHandlerMatch: 'origin',
        notificationClickHandlerAction: 'focus',
        autoRegister: true,
        autoResubscribe: true,
      })
        .then(() => {
          OneSignal.Slidedown.promptPush();
          setSubscriptionId(OneSignal.User.PushSubscription.id || null);
        })
        .catch((err) => {
          console.log(`OneSignal initialization failed: ${err} `);
        });
    }
  }, [setSubscriptionId]);

  useEffect(() => {
    const navigateSpecificPage = (event: MessageEvent<any>) => {
      if (event.data?.action === 'navigate' && event.data?.url) {
        navigate(event.data.url);
      }
    };
    navigator?.serviceWorker?.addEventListener('message', navigateSpecificPage);
    return () => {
      navigator?.serviceWorker?.removeEventListener(
        'message',
        navigateSpecificPage
      );
    };
  }, [navigate]);

  return (
    <QueryClientProvider client={queryClient}>
      <div
        id="app"
        className={`${
          !isMobile ? `w-[50vh] relative mx-auto translate-x-0` : ``
        } h-[100dvh] overflow-hidden bg-white`}
      >
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Navigate to={routes.SEARCH} />} />
            {routers
              .filter((route) =>
                user?.isLimited ? !LIMITED_ROUTES.includes(route.path) : true
              )
              .map(
                ({ path, component: PageComponent, isProtected, children }) => (
                  <Route
                    key={path}
                    path={path}
                    element={
                      isProtected ? (
                        <ProtectedRoute>
                          <PageComponent />
                        </ProtectedRoute>
                      ) : (
                        <PageComponent />
                      )
                    }
                  >
                    {children?.map((child) => {
                      const ChildComponent = child.component;
                      return (
                        <Route
                          key={child.path}
                          path={child.path}
                          element={<ChildComponent />}
                          index={child.index}
                        />
                      );
                    })}
                  </Route>
                )
              )}
          </Route>
        </Routes>
        <WarningModal />
        <TutorialModal />
        <div id="recaptcha-container" className="invisible"></div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="light"
      />
    </QueryClientProvider>
  );
}

export default App;
