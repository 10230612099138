import { BankTransferInformation, Header, TecoAsset } from 'components';
import { useEffect, useMemo, useState } from 'react';
import { ETransferStep, TBankDetail, TListResult } from 'types';
import { queryKeys, TEXT_STRING } from '../../../constants';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getPendingRequests } from 'api';

const str = TEXT_STRING.POINTS;

function BuyPoints() {
  const queryClient = useQueryClient();
  const [bankStep, setBankStep] = useState<ETransferStep>(ETransferStep.APPLY);

  const { data, isFetching } = useQuery<TListResult<TBankDetail>>({
    queryKey: [queryKeys.PENDING_REQUEST_POINT],
    queryFn: () =>
      getPendingRequests({
        currentPage: 1,
        pageSize: 1,
      }),
  });

  const pendingItem = useMemo(() => data?.data?.[0], [data?.data]);

  const showTab = useMemo(
    () => bankStep === ETransferStep.APPLY || !!pendingItem,
    [bankStep, pendingItem]
  );

  useEffect(() => {
    queryClient.refetchQueries({ queryKey: [queryKeys.POINTS] });
  }, [queryClient]);

  return (
    <>
      <Header title={str.TITLE_OF_BUY_POINT} />
      <div className="pt-8 px-4 pb-20 flex flex-col h-full overflow-y-auto">
        {showTab && (
          <div className="sticky z-1 flex flex-col gap-7">
            <TecoAsset />
          </div>
        )}

        <BankTransferInformation
          className={showTab ? 'mt-8' : ''}
          currentStep={bankStep}
          setCurrentStep={setBankStep}
          bankTransferDetail={pendingItem}
          isLoading={isFetching}
        />
      </div>
    </>
  );
}

export default BuyPoints;
