import { Text } from 'components';
import TEXT_APP_INFO from 'constants/appInfo';

const SpecialCommercialLaw = () => {
  return (
    <>
      {TEXT_APP_INFO.SPECIAL_COMMERCIAL_LAW_CONTENT.map((item, index) => {
        return (
          <div key={index}>
            <Text className="mt-4 leading-7">{item.text}</Text>
            <Text className="mt-4">{item.content}</Text>
          </div>
        );
      })}
    </>
  );
};
export default SpecialCommercialLaw;
