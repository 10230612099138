import { GiftPaymentStep, PostItem, Text, VirtualizedList } from 'components';
import React, {
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  EGiftPaymentStep,
  TBodyPushNotification,
  TPostDetail,
  TPostResponse,
  TResPaymentGift,
} from 'types';
import { CellMeasurerCache, ScrollParams } from 'react-virtualized';
import { PAGE_SIZE, TEXT_STRING } from '../../constants';
import LoadingPost from 'components/PostItem/LoadingPost';
import { useToast } from 'hooks';
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { pushNotificationMessage, updateChannelActive } from 'api';
import { usePubNub } from 'pubnub-react';
import { useUserStore } from 'store';

type Props = {
  loading?: boolean;
  postScrollY?: number;
  setPostScrollY: (value?: number) => void;
  cachePost?: CellMeasurerCache;
  onScrollY?: (params?: ScrollParams) => void;
  Banner: (props: { measure?: () => void }) => ReactElement;
  onClickAvatar?: () => void;
  queryKey: string[];
  getPostList(currentPage: number, pageSize: number): Promise<TPostResponse>;
  enabled?: boolean;
};

function PostView({
  loading = false,
  postScrollY,
  setPostScrollY,
  cachePost,
  onScrollY,
  Banner,
  onClickAvatar,
  queryKey,
  getPostList,
  enabled = false,
}: Props) {
  // Hooks
  const { toastSuccess } = useToast();
  const pubnub = usePubNub();
  const { user } = useUserStore();
  // States
  const [stepPayment, setStepPayment] = useState<EGiftPaymentStep>();
  const [premiumPostInfo, setPremiumPostInfo] = useState<TPostDetail>();

  // Query
  const { data, isLoading, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam }) => getPostList(pageParam, PAGE_SIZE),
    initialPageParam: 1,
    getNextPageParam: (lastPage, _, currentPage) => {
      return lastPage?.totalPage > currentPage ? currentPage + 1 : undefined;
    },
    enabled,
  });
  // Mutation
  const { mutate: mutatePushNotification } = useMutation({
    mutationFn: (body: TBodyPushNotification) => {
      return pushNotificationMessage(body);
    },
    retry: 0,
  });

  const { mutate: mutateChannelActive } = useMutation({
    mutationFn: ({ channelId }: { channelId: string }) => {
      return updateChannelActive(channelId);
    },
    retry: 0,
  });

  // Memo, callback
  const listView = useMemo(
    () => data?.pages.flatMap(({ data }) => data) ?? [],
    [data?.pages]
  );

  const onConfirmGift = useCallback((data: TPostDetail) => {
    setStepPayment(EGiftPaymentStep.CHOOSE_METHOD);
    setPremiumPostInfo(data);
  }, []);

  const RowItem = useCallback(
    ({ index, measure, registerChild, style }: any) => {
      const item = listView[index];

      return (
        <div ref={registerChild} style={style}>
          {index === 0 && <Banner />}
          {item ? (
            <div>
              <PostItem
                className="p-4"
                data={item}
                onClickAvatar={onClickAvatar}
                onConfirmGift={onConfirmGift}
              />
              <hr className="solid my-8" />
            </div>
          ) : (
            <LoadingPost index={index} />
          )}
        </div>
      );
    },
    [Banner, onClickAvatar, onConfirmGift, listView]
  );

  const sendGiftMessage = useCallback(
    async (payload: TResPaymentGift) => {
      const {
        message,
        image: giftImage,
        animations: giftAnimations,
        name: giftName,
        channel,
      } = payload;
      let messageBody = `${message.trim()}\n`;
      if (giftName)
        messageBody += TEXT_STRING.COMMON.QUOTE.replace('$value', giftName);
      const notificationTitle = `${user?.name || ''} ${
        TEXT_STRING.COMMON.RECEIVED_MESSAGE
      }`;
      const gift = {
        image: giftImage,
        animations: giftAnimations,
        name: giftName,
      };

      const messagePayload = {
        text: message,
        pn_gcm: {
          notification: {
            title: notificationTitle,
            body: messageBody,
            sound: 'default',
          },
          data: {
            key: channel?.id,
            type: channel?.type,
          },
        },
        gift,
      };

      if (message || gift) {
        await pubnub.publish({
          channel: channel?.id,
          message: messagePayload,
        });
      }

      mutatePushNotification({
        channelId: channel?.id,
        channelType: channel?.type || '',
        message: messageBody,
        title: notificationTitle,
      });

      // send channel active lastMessage
      mutateChannelActive({ channelId: channel?.id });

      const channelUsers = channel?.members?.filter(
        (item) => item.uuid !== user?.uuid
      );

      if (channelUsers) {
        Promise.all(
          channelUsers.map(({ uuid }) =>
            pubnub.publish({
              channel: uuid,
              message: {
                channel: channel?.id,
                text: messageBody,
              },
            })
          )
        );
      }
    },
    [
      mutateChannelActive,
      mutatePushNotification,
      pubnub,
      user?.name,
      user?.uuid,
    ]
  );

  // Effect
  useEffect(() => {
    setPostScrollY(undefined);
  }, [setPostScrollY]);

  if (loading || isLoading) {
    return (
      <div className="flex-1">
        <div className="h-[20vh] bg-gray-300 animate-pulse mb-4"></div>
        {Array.from(Array(3).keys()).map((_, index) => (
          <LoadingPost index={index} key={index} />
        ))}
      </div>
    );
  }

  return (
    <>
      {!listView.length ? (
        <>
          <Banner />
          <Text center bold className={`py-20`}>
            {TEXT_STRING.COMMON.NO_DATA}
          </Text>
        </>
      ) : (
        <div className="flex-1">
          <VirtualizedList
            rowCount={listView.length + +hasNextPage}
            hasMore={hasNextPage}
            fetchMore={fetchNextPage}
            dataLength={listView.length}
            scrollTop={postScrollY}
            onScroll={onScrollY}
            cache={
              // TODO reset cache when rowCount changes
              new CellMeasurerCache({
                defaultHeight: 500,
                fixedWidth: true,
              })
            }
            overscanRowCount={5}
            RowItem={RowItem}
          />
          <GiftPaymentStep
            step={stepPayment}
            onClose={() => {
              setStepPayment(undefined);
            }}
            giftId={+(premiumPostInfo?.gift?.id || 0)}
            femaleId={+(premiumPostInfo?.user?.id || 0)}
            postId={+(premiumPostInfo?.id || 0)}
            onNextStep={() => {}}
            onSendMessage={(res) => {
              sendGiftMessage({ ...res, message: '' });
            }}
            onChooseStep={(step) => setStepPayment(step)}
            onSuccess={() => {
              toastSuccess(TEXT_STRING.COMMON.SUCCESS);
              setStepPayment(undefined);
            }}
          />
        </div>
      )}
    </>
  );
}

export default React.memo(PostView);
