import { COOKIE_KEYS, storageKeys } from '../constants';
import { TUser } from 'types';
import { getCookies } from 'utils';
import { create } from 'zustand';

type State = {
  isAuthenticated: boolean;
  clientId: string | null;
  user?: TUser;
  mgmChannelId?: string;
  subscriptionId: string | null;
  isUserApplyABTesting?: boolean;
};

type Actions = {
  setIsAuthenticated: (value: boolean) => void;
  setClientId: (value: string) => void;
  setUser: (user: TUser) => void;
  removeUser: () => void;
  setMgmChannelId: (id: string) => void;
  setSubscriptionId: (id: string | null) => void;
  setIsUserApplyABTesting: (value: boolean) => void;
};

const useUserStore = create<State & Actions>((set) => ({
  isUserApplyABTesting: false,
  isAuthenticated: !!localStorage.getItem(storageKeys.ACCESS_TOKEN),
  clientId:
    getCookies(COOKIE_KEYS.CLIENT_ID) ||
    localStorage.getItem(storageKeys.CLIENT_ID),
  user: undefined,
  subscriptionId: null,
  setIsAuthenticated: (value: boolean) =>
    set(() => ({ isAuthenticated: value })),
  setClientId: (value: string) => set(() => ({ clientId: value })),
  setUser: (user: TUser) => set(() => ({ user: user })),
  removeUser: () => set(() => ({ user: undefined })),
  setMgmChannelId: (channelId) => set(() => ({ mgmChannelId: channelId })),
  setSubscriptionId: (id) => id && set(() => ({ subscriptionId: id })),
  setIsUserApplyABTesting: (value: boolean) => {
    set(() => ({
      isUserApplyABTesting: value,
    }));
  },
}));

export default useUserStore;
