import React, { useCallback } from 'react';
import Modal from '../Modal';
import { storageKeys, TEXT_STRING, tutorialModal } from '../../constants';
import Button from 'components/Button';
import Text from 'components/Text';
import { detectSystemMobile } from 'utils';
import { ESystemMobile } from 'types';
import TutorialList from './TutorialList';
import { useSettingStore } from 'store';
import Icon from 'components/Icon';

type Props = {
  onContinue?: () => void;
  onClose?: () => void;
  text?: Record<string, string>;
  open?: boolean;
};
const tutorial =
  detectSystemMobile() === ESystemMobile.IOS
    ? tutorialModal.iphone
    : tutorialModal.android;

function TutorialModal({
  onContinue,
  onClose,
  text = { title: tutorial.title, button: TEXT_STRING.COMMON.CONTINUE },
  open = false,
}: Props) {
  // Hook
  const { openTutorial, setOpenTutorial } = useSettingStore();

  const onConfirm = useCallback(() => {
    onContinue && onContinue();
    setOpenTutorial(false);
    localStorage.setItem(storageKeys.IS_CONFIRM_TUTORIAL, 'true');
  }, [setOpenTutorial, onContinue]);

  return (
    <Modal
      open={open || openTutorial}
      onClose={() => {}}
      className="bg-white h-[90dvh] p-6 mx-6 fixed"
      zIndex="z-20"
    >
      <div className="h-full overflow-y-auto flex flex-col justify-between gap-8 text-sm">
        <Text bold className="px-8" center={!onClose}>
          {text.title}
        </Text>
        {onClose && (
          <div
            onClick={onClose}
            className="absolute right-6 top-5 cursor-pointer"
          >
            <Icon icon={'close'} color="black" />
          </div>
        )}
        <TutorialList steps={tutorial.steps} />
        <div className="sticky bottom-0 w-full">
          <Button onClick={onConfirm} className="p-4 w-full" bold>
            {text.button}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default TutorialModal;
