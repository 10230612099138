import { Text } from 'components';
import TEXT_APP_INFO from 'constants/appInfo';

const PrivacyPolicy = () => {
  return (
    <>
      {TEXT_APP_INFO.PRIVACY_POLICY_CONTENT.map((item, index) => {
        return (
          <div key={index}>
            <Text bold className="mt-4 leading-7">
              {item.text}
            </Text>
            <Text className="indent-4">{item?.header}</Text>
            <Text className="pl-6">{item.content}</Text>
            <ul className="list-none pl-0">
              {item.children?.map((c, ind) => {
                return (
                  <li key={ind}>
                    <Text className="leading-7 pl-3">{c.text}</Text>
                    {c.children && (
                      <ul className="pl-6">
                        {c.children.map((text: string, i: number) => {
                          return (
                            <div key={i} className="flex gap-2">
                              <span>({i + 1})</span>
                              <li>
                                <Text className="leading-7">{text}</Text>
                              </li>
                            </div>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </>
  );
};
export default PrivacyPolicy;
