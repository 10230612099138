const routes = {
  SIGNUP: '/signup',
  LOGIN: '/login',
  SEARCH: '/search',
  HOME: '/home',
  CREATE_POST: '/home/post',
  CALL: '/call',
  ORDER_TRAVEL: '/call/travel',
  MESSAGE: '/message',
  CONVERSATION: '/message/:id',
  PAYMENT: '/payment/:id',
  MY_PAGE: '/my-page',
  HOW_TO_USE: '/my-page/how-to-use',
  FAQ: '/my-page/faq',
  APP_INFO: '/app-info',
  DETAIL_FEMALE: '/search/detail-female/:id',
  STAR_DETAIL: '/my-page/detail-rank',
  PREVIEW_MALE: '/my-page/edit-profile/preview',
  EDIT_MALE: '/my-page/edit-profile',
  FOLLOWING: '/my-page/following',
  FEMALE_MET: '/my-page/female-met',
  PAYMENT_METHOD: '/my-page/payment',
  GAME_PAGE: '/game-page',
  ORDER_HISTORY: '/my-page/order-history',
  REVIEW_GIRL: '/review-girl',
  BUY_POINTS: '/my-page/buy-points',
  POINT_HISTORY: '/my-page/point-history',
  PREMIUM_PURCHASED: '/my-page/premium-purchased',
  POINT_HISTORY_DETAIL: '/my-page/point-history/:pointId',
  POINT_USAGE_HISTORY: '/my-page/point-usage-history',
  INTRODUCING_FRIEND: '/my-page/introducing-friend',
  SPECIAL_COMMERCIAL_LAW: '/app-info/special-commercial-law',
  PRIVACY_POLICY: '/app-info/privacy-policy',
  TERMS_OF_SERVICE: '/app-info/terms-of-service',
};

const LIMITED_ROUTES: string[] = [
  routes.BUY_POINTS,
  routes.POINT_HISTORY,
  routes.POINT_HISTORY_DETAIL,
  routes.POINT_USAGE_HISTORY,
];

export { routes, LIMITED_ROUTES };
